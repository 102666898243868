export const getLogs = payload => {
    return {
        type: "GET_LOGS",
        payload: payload
    }
}

export const getLog = payload => {
    return {
        type: "GET_LOG",
        payload: payload
    }
}