import React from "react"

import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";

import { themeFooter } from "../../../../../../Themes/themes";
import { LogoImages } from "../../../../../Common/Logo/logo";

const theme = themeFooter;

export function Footer() {
    return(
        <div className="container">
            <ThemeProvider theme={theme}>
            {/*<AppBar position="static" color="primary" sx={{ bgColor: "#003580" }}>
                <Container maxWidth="md" sx={{ bgColor: "#003580" }}>
                    <Toolbar sx={{ bgColor: "#003580", alignItems: "center", justifyContent: "center" }}>
                        <Button  sx={{ color: "white", borderColor: "white" }}
                                 variant="outlined"
                                 href={
                                     Cookies.get('token') ?
                                     window.location.protocol + "//" + "extranet" + "." + window.location.host + "/properties/create" :
                                     window.location.protocol + "//" + "account" + "." + window.location.host
                                 }>Зарегистрировать свой объект</Button>
                    </Toolbar>
                </Container>
            </AppBar>
            <div style={{ width: '100%', height: 1 }}>
            </div>
            <AppBar position="static" color="primary" sx={{ bgcolor: "#003580" }}>
                <Container>
                    <Toolbar>
                        <Stack spacing={2} direction="row">
                            <a className={s.footerTopLink} href="#">О kvartira-na-sutki.com</a>
                            <a className={s.footerTopLink} href="#">Правила и условия</a>
                            <a className={s.footerTopLink} href="#">Как мы работаем</a>
                            <a className={s.footerTopLink} href="#">Положение о конфиденциальности и cookie-файлах</a>
                            <a className={s.footerTopLink} href="#">Центр поддержки</a>
                        </Stack>
                    </Toolbar>
                </Container>
            </AppBar>*/}
                <AppBar position="static">
                    <Container fixed>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ fontSize: "12px", margin:"10px" }}>Copyright © 2022–{new Date().getFullYear()} kvartira-na-sutki.com™. Все права защищены.</div>
                            <div><LogoImages /></div>
                        </div>
                    </Container>
                </AppBar>
            </ThemeProvider>
        </div>
    )
}
