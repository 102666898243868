export const getAmenityCategories = payload => {
    return {
        type: "GET_AMENITY_CATEGORIES",
        payload: payload
    }
}

export const getAmenityCategory = payload => {
    return {
        type: "GET_AMENITY_CATEGORY",
        payload: payload
    }
}

export const setName = payload => {
    return {
        type: "SET_NAME",
        payload: payload
    }
}

export const setIsActive = payload => {
    return {
        type: "SET_IS_ACTIVE",
        payload: payload
    }
}

export const setIconPath = payload => {
    return {
        type: "SET_ICON_PATH",
        payload: payload
    }
}

export const setOrder = payload => {
    return {
        type: "SET_ORDER",
        payload: payload
    }
}