import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from "./redux/redux";
import App from './redux/containers/basic';
import './index.scss';
import './index.css';

let Store = store()

const Show = (state) => {
    ReactDOM.render(
            <Provider store={state}>
                <App />
            </Provider>
        , document.getElementById('root')
    );
}

Show(Store);
