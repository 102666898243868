import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import {
    getCountryRegisters,
    getGeoInfo,
    getUserPhones,
    getUserProfile,
} from "../../../../../../../Requests/CommonRequests/commonRequests";
import { AccountHeader } from "../../../../../../Common/Mobile/AccountHeader/accountHeader";
import {
    ComponentPersonalInfo
} from "../../../../../../Common/Cards/CardsAccountSettings/PersonalInfo/componentPersonalInfo";
import { SimpleLoader } from "../../../../../../Common/Loader/simpleLoader";

export function PersonalInfo(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [geoData, setGeoData] = useState(null);
    const [countryCallingCode, setCountryCallingCode] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        Promise.all([getUserProfile(props), getUserPhones(props), getCountryRegisters(props)])
            .then(() => getGeoInfo())
            .then((res) => {setGeoData(res); setCountryCallingCode(res.country_calling_code); setPhone(res.country_calling_code)})
            .then(() => setIsLoaded(true))
    }, [])

    return (
        <AccountHeader name={'Персональные данные'}>
            {isLoaded ? (
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Link to={-1}>
                            <Button variant="text" sx={{ textDecoration: 'underline', color: 'black' }}><KeyboardArrowLeftIcon />Аккаунт</Button>
                        </Link>

                        <ComponentPersonalInfo
                            {...props}
                            phone={phone}
                            countryCallingCode={countryCallingCode}
                            geoData={geoData}
                            setPhone={(value) => setPhone(value)}
                            setCountryCallingCode={(value) => setCountryCallingCode(value)}
                        />
                    </Box>
                </Container>
            ) : (
                <SimpleLoader />
            )}
        </AccountHeader>
    );
}
