export const getReviews = payload => {
    return {
        type: "GET_REVIEWS",
        payload: payload
    }
}

export const getReview = payload => {
    return {
        type: "GET_REVIEW",
        payload: payload
    }
}

export const setScore = payload => {
    return {
        type: "SET_SCORE",
        payload: payload
    }
}

export const setBookingId = payload => {
    return {
        type: "SET_BOOKING_ID",
        payload: payload
    }
}

export const setPublicComment = payload => {
    return {
        type: "SET_PUBLIC_COMMENT",
        payload: payload
    }
}

export const setPrivateComment = payload => {
    return {
        type: "SET_PRIVATE_COMMENT",
        payload: payload
    }
}

export const setIsAnonymous = payload => {
    return {
        type: "SET_IS_ANONYMOUS",
        payload: payload
    }
}

export const setIsReadManager = payload => {
    return {
        type: "SET_IS_READ_MANAGER",
        payload: payload
    }
}

export const setIsReadHost = payload => {
    return {
        type: "SET_IS_READ_HOST",
        payload: payload
    }
}

export const setExpandedReviewScores = payload => {
    return {
        type: "SET_EXPANDED_REVIEW_SCORES",
        payload: payload
    }
}

export const setPropertyId = payload => {
    return {
        type: "SET_PROPERTY_ID",
        payload: payload
    }
}

export const setStatus = payload => {
    return {
        type: "SET_STATUS",
        payload: payload
    }
}