import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { useState } from 'react';

import { Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';

import { ChartBar as ChartBarIcon } from '../Icons/chart-bar';
import { ShoppingBag as ShoppingBagIcon } from '../Icons/shopping-bag';
import { Users as UsersIcon } from '../Icons/users';
import RateReviewIcon from '@mui/icons-material/RateReview';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { Logo } from './logo';
import { NavItem } from './nav-item';

export const DashboardSidebar = (props) => {
    const { open, onClose } = props;
    const [role] = useState(props.user.permissions.find((permission) => permission.name === 'admin-panel') ? 'админ' : '')
    const [fullName] = useState(props.user.user_profile.first_name + ' ' + props.user.user_profile.last_name)

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const items = [
        {
            to: '/admin/dashboard',
            icon: (<ChartBarIcon fontSize="small" />),
            title: 'Просмотр'
        },
        {
            to: '/admin/bookings',
            icon: (<ChartBarIcon fontSize="small" />),
            title: 'Бронирования'
        },
        {
            to: '/admin/users',
            icon: (<UsersIcon fontSize="small" />),
            title: 'Пользователи'
        },
        {
            to: '/admin/hosts',
            icon: (<UsersIcon fontSize="small" />),
            title: 'Хозяева'
        },
        {
            to: '/admin/properties',
            icon: (<ShoppingBagIcon fontSize="small" />),
            title: 'Объявления'
        },
        {
            to: '/admin/reviews',
            icon: (<RateReviewIcon fontSize="small" />),
            title: 'Оценки и отзывы',
            badge: props.reminderList.reviews,
        },
        {
            to: '/admin/review-score-categories',
            icon: (<ShoppingBagIcon fontSize="small" />),
            title: 'К. отзывов'
        },
        {
            to: '/admin/logs',
            icon: (<ShoppingBagIcon fontSize="small" />),
            title: 'Логи'
        },
        {
            to: '/admin/amenities',
            icon: (<ShoppingBagIcon fontSize="small" />),
            title: 'Удобства'
        },
        {
            to: '/admin/amenity-categories',
            icon: (<ShoppingBagIcon fontSize="small" />),
            title: 'К. удобств'
        },
        {
            to: '/admin/currencies',
            icon: (<ShoppingBagIcon fontSize="small" />),
            title: 'Валюты'
        },
        {
            to: '/admin/regions',
            icon: (<ShoppingBagIcon fontSize="small" />),
            title: 'Регионы'
        },
        {
            to: '/admin/property-types',
            icon: (<ShoppingBagIcon fontSize="small" />),
            title: 'Типы квартир'
        },
        {
            to: '/admin/user-phones',
            icon: (<ShoppingBagIcon fontSize="small" />),
            title: 'Телефоны'
        },
        {
            to: '/admin/rating-coefficient',
            icon: (<ShoppingBagIcon fontSize="small" />),
            title: 'Коэффициенты рейтинга'
        },
        {
            to: '/admin/booking-feedbacks',
            icon: (<FeedbackIcon fontSize="small" />),
            title: 'Причины отмены бронирования'
        },
        {
            to: '/admin/settings',
            icon: (<SettingsApplicationsIcon fontSize="small" />),
            title: 'Настройки'
        },
    ];

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <div>
                    <Box sx={{ p: 3 }}>
                        <Link
                            to={'/'}
                        >
                            <Logo
                                sx={{
                                    height: 42,
                                    width: 42
                                }}
                            />
                        </Link>
                    </Box>
                    <Box sx={{ px: 2 }}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                px: 3,
                                py: '11px',
                                borderRadius: 1
                            }}
                        >
                            <div>
                                <Typography
                                    color="inherit"
                                    variant="subtitle1"
                                    component="div"
                                >
                                    {fullName}
                                </Typography>
                                <Typography
                                    color="neutral.400"
                                    variant="body2"
                                    component="div"
                                >
                                    {'Доступ: '}
                                    {role}
                                </Typography>
                            </div>
                        </Box>
                    </Box>
                </div>
                <Divider
                    sx={{
                        borderColor: '#2D3748',
                        my: 3
                    }}
                />
                <Box sx={{ flexGrow: 1 }}>
                    {items.map((item) => (
                        <NavItem
                            id={item.title}
                            key={item.title}
                            icon={item.icon}
                            to={item.to}
                            title={item.title}
                            badge={item.badge}
                        />
                    ))}
                </Box>
                <Divider sx={{ borderColor: '#2D3748' }} />
            </Box>
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.900',
                        color: '#FFFFFF',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: 280
                }
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};