import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState, Suspense } from "react";

import { ErrorBoundary } from "../../../../Common/ErrorBoundary/errorBoundary";
import { Menu } from "./Menu/menu";
import { getUser } from "../../../../../Requests/CommonRequests/commonRequests";
import Cookies from "js-cookie";
import { SimpleLoader } from "../../../../Common/Loader/simpleLoader";

export function Layout(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        if (Cookies.get('token')) {
            getUser(props)
                .then((res) =>
                    {
                        if (res.payload?.message === 'Unauthenticated.') {
                            navigate('/login')
                        } else {
                            setIsLoaded(true)
                        }
                    },
                    (error) => console.log(error)
                )
        } else {
            navigate('/login')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <ErrorBoundary>
                <Suspense fallback={<div></div>}>
                    {isLoaded && <Outlet />}
                </Suspense>
            </ErrorBoundary>

            <Menu {...props} />
        </div>
    );
}