const initialState = {
    //рефакторинг
    properties: null,
    propertiesForMap: null,
    property: {
        rules: {
            check_in_time: "12:00",
            check_out_time: "14:00",
            min_stay_days: 1,
            down_payment_amount: 0,
        },
    },

    //
    id: null,
    status: null,
    startDate: null,
    endDate: null,
    closedBookingDates: null,
    currency: null,
    phone: null,
    region: {
        id: 3
    },
    ad_type: null,
    lat: null,
    lng: null,
    name: null,
    description: null,
    price: null,
    guests: null,
    instantBookingAvailable: false,
    roomsAndSpaces: {},
    costPerPeriod: null,
    period: null,
    address: null,
    address_supplement: null,
    additionalGuests: null,
    costPerAdditionalGuest: null,
    additionalPrice: null,
    amenities: [],
    //photos
    photos: null,
    initialPhotos: null,
}
//TODO
export default function property(state= initialState, action) {
    switch(action.type){
        case "GET_PROPERTIES":
            return {...state, properties: action.payload};
        case "GET_PROPERTY":
            return {
                ...state,
                guests: action.payload.guests,
                name: action.payload.name,
                price: action.payload.price,
                description: action.payload.description,
                currency: action.payload.currency,
                phone: action.payload.phone,
                ad_type: action.payload.ad_type,
                region: action.payload.region,
                lat: action.payload.lat,
                lng: action.payload.lng,
                user: action.payload.user,
                photos: action.payload.photos,
                closedBookingDates: action.payload.closed_booking_dates,
                instantBookingAvailable: action.payload.instant_booking_available,
                roomsAndSpaces: action.payload.rooms_and_spaces,
                //costPerPeriod: action.payload.cost_per_period,
                period: action.payload.period,
                id: action.payload.id,
                address: action.payload.address,
                address_supplement: action.payload.address_supplement,
                status: action.payload.status,
                additionalGuests: action.payload.additional_guests,
                costPerAdditionalGuest: action.payload.cost_per_additional_guest,
                amenities: action.payload.amenities,
                moderatedReviews: action.payload.moderatedReviews,
                property: {...state.property, rules: action.payload.rules }
            };
        case "SET_CALENDAR_DATE":
            return {...state, startDate: action.payload.startDate, endDate: action.payload.endDate};
        case "SET_COORDS":
            return {...state, lat: action.payload.lat, lng: action.payload.lng};
        case "SET_PHOTOS":
            return {...state, photos: action.payload};
        case "GET_INITIAL_PHOTOS":
            return {...state, initialPhotos: action.payload};
        case "GET_ICALS":
            return {...state, property: {...state.property, iCals: action.payload}};
        case "GET_ICAL":
            return {...state, property: {...state.property, iCal: action.payload}};
        case "DELETE_PHOTOS":
            return {...state, photos: [], initialPhotos: []};
        case "SET_CURRENCY":
            return {...state, currency: action.payload};
        case "SET_PHONE":
            return {...state, phone: {...state.phone, id: action.payload}};
        case "SET_REGION":
            return {...state, region: {...state.region, id: action.payload}};
        case "SET_AD_TYPE":
            return {...state, ad_type: {...state.ad_type, id: action.payload}};
        case "SET_NAME":
            return {...state, name: action.payload};
        case "SET_DESCRIPTION":
            return {...state, description: action.payload};
        case "SET_PRICE":
            return {...state, price: action.payload};
        case "SET_GUESTS":
            return {...state, guests: action.payload};
        case "SET_INSTANT_BOOKING_AVAILABLE":
            return {...state, instantBookingAvailable: action.payload};
        case "GET_CLOSED_BOOKING_DATES":
            return {...state, closedBookingDates: action.payload};
        case "SET_SUMMARY_BEDROOMS":
            return {...state, roomsAndSpaces: {...state.roomsAndSpaces, summary: {...state.roomsAndSpaces.summary, number_of_bedrooms: action.payload}}};
        case "SET_SUMMARY_BEDS":
            return {...state, roomsAndSpaces: {...state.roomsAndSpaces, summary: {...state.roomsAndSpaces.summary, number_of_beds: action.payload}}};
        case "SET_BEDROOMS_INFO":
            return {...state, roomsAndSpaces: {...state.roomsAndSpaces, bedrooms_info: action.payload}};
        case "SET_SUMMARY_BATHROOMS":
            return {...state, roomsAndSpaces: {...state.roomsAndSpaces, summary: {...state.roomsAndSpaces.summary, number_of_bathrooms: action.payload}}};
        case "SET_COST_PER_PERIOD":
            return {...state, costPerPeriod: action.payload };
        case "SET_ADDRESS":
            return {...state, address: action.payload };
        case "SET_ADDRESS_SUPPLEMENT":
            return {...state, address_supplement: action.payload };
        case "SET_STATUS":
            return {...state, status: action.payload };
        case "SET_ADDITIONAL_GUESTS":
            return {...state, additionalGuests: action.payload };
        case "SET_COST_PER_ADDITIONAL_GUEST":
            return {...state, costPerAdditionalGuest: action.payload };
        case "SET_ADDITIONAL_PRICE":
            return {...state, additionalPrice: action.payload };
        case "SET_AMENITIES":
            return {...state, amenities: action.payload };
        case "GET_RULES":
            return {...state, property: {...state.property, rules: action.payload }};
        case "SET_RULES_CHECK_IN_TIME":
            return {...state, property: {...state.property, rules: {...state.property.rules, check_in_time: action.payload} }};
        case "SET_RULES_CHECK_OUT_TIME":
            return {...state, property: {...state.property, rules: {...state.property.rules, check_out_time: action.payload} }};
        case "SET_RULES_MIN_STAY_DAYS":
            return {...state, property: {...state.property, rules: {...state.property.rules, min_stay_days: action.payload} }};
        case "SET_RULES_SUITABLE_FOR_CHILDREN":
            return {...state, property: {...state.property, rules: {...state.property.rules, suitable_for_children: action.payload} }};
        case "SET_RULES_SUITABLE_FOR_INFANTS":
            return {...state, property: {...state.property, rules: {...state.property.rules, suitable_for_infants: action.payload} }};
        case "SET_RULES_PETS_ALLOWED":
            return {...state, property: {...state.property, rules: {...state.property.rules, pets_allowed: action.payload} }};
        case "SET_RULES_SMOKING_ALLOWED":
            return {...state, property: {...state.property, rules: {...state.property.rules, smoking_allowed: action.payload} }};
        case "SET_RULES_EVENTS_ALLOWED":
            return {...state, property: {...state.property, rules: {...state.property.rules, events_allowed: action.payload} }};
        case "SET_RULES_WITH_RENTAL_AGREEMENT":
            return {...state, property: {...state.property, rules: {...state.property.rules, with_rental_agreement: action.payload} }};
        case "SET_RULES_DAMAGE_DEPOSIT_AMOUNT":
            return {...state, property: {...state.property, rules: {...state.property.rules, damage_deposit_amount: action.payload} }};
        case "SET_RULES_DAMAGE_DEPOSIT_CURRENCY":
            return {...state, property: {...state.property, rules: {...state.property.rules, damage_deposit_currency: action.payload} }};
        case "SET_RULES_DOWN_PAYMENT_AMOUNT":
            return {...state, property: {...state.property, rules: {...state.property.rules, down_payment_amount: action.payload} }};
        case "SET_RULES_ADDITIONAL_RULES":
            return {...state, property: {...state.property, rules: {...state.property.rules, additional_rules: action.payload} }};
        case "GET_PROPERTIES_FOR_MAP":
            return {...state, propertiesForMap: action.payload };
        default: return state;
    }
};