import React from "react"

import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";

import { themeFooter} from "../../../../../../Themes/themes";
import { LogoImages } from "../../../../../Common/Logo/logo";

const theme = themeFooter;

export function Footer() {
    return(
        <div className="container">
            <ThemeProvider theme={theme}>
                {/*<AppBar position="static" color="primary" sx={{ bgcolor: "#003580" }}>
                    <Container>
                        <Toolbar>
                            <Stack spacing={2} direction="row">
                                <a className={s.footerTopLink} href="#">О kvartira-na-sutki.com</a>
                                <a className={s.footerTopLink} href="#">Правила и условия</a>
                                <a className={s.footerTopLink} href="#">Как мы работаем</a>
                                <a className={s.footerTopLink} href="#">Положение о конфиденциальности и cookie-файлах</a>
                                <a className={s.footerTopLink} href="#">Центр поддержки</a>
                            </Stack>
                        </Toolbar>
                    </Container>
                </AppBar>*/}
                <AppBar position="static">
                    <Container fixed>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ fontSize: "12px", margin:"10px" }}>Copyright © 2022–{new Date().getFullYear()} Kvartiranasutki.com™. Все права защищены.</div>
                            <div><LogoImages /></div>
                        </div>
                    </Container>
                </AppBar>
            </ThemeProvider>
        </div>
    )
}
