const initialState = {
    bookingFeedback: null,
    bookingFeedbacks: null,
}

export default function bookingFeedback(state= initialState, action) {
    switch(action.type) {
        case "GET_BOOKING_FEEDBACK":
            return {...state, bookingFeedback: action.payload};
        case "GET_BOOKING_FEEDBACKS":
            return {...state, bookingFeedbacks: action.payload};
        default: return state;
    }
};