import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";

import { Content } from "./Basic/Desktop/Content/content";
import { MobileContent } from "./Basic/Mobile/Content/content";

const subdomains = {
    extranet: '/host',
    secure: '/guest',
    admin: '/admin',
    www: ''
}

export function App(props) {
    const isDesktop = useMediaQuery({ query: '(min-width: 748px)' })

    if (!window.location.hostname.startsWith('kvartiranasutki')) {
        window.location = ((window.location.protocol === 'https:' ? process.env.REACT_APP_BASIC : process.env.REACT_APP_LOCAL_BASIC)
            + subdomains[window.location.host.split('.')[0]]
            + window.location.pathname + window.location.search);
    } else {
        return (
            <>
                <Helmet>
                    <link rel="canonical" href={`${(window.location.protocol === 'https:' ? process.env.REACT_APP_BASIC : process.env.REACT_APP_LOCAL_BASIC)}`} />
                </Helmet>

                {isDesktop ? <Content {...props} /> : <MobileContent {...props} />}
            </>
        )
    }
}
