import { Outlet } from "react-router-dom";
import { Suspense } from "react";

import { ErrorBoundary } from "../../../Common/ErrorBoundary/errorBoundary";
import { SimpleLoader } from "../../../Common/Loader/simpleLoader";
import { Menu } from "../Layout/Menu/menu"

export function Layout() {
    return (
        <div>
             <ErrorBoundary>
                 <Suspense fallback={<SimpleLoader />}>
                    <Outlet />
                 </Suspense>
                 <Menu />
             </ErrorBoundary>
        </div>
    );
}