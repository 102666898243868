import React from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import { ThemeProvider } from "@mui/material/styles";

import s from "./footer.module.scss"
import { themeFooter } from "../../../../../Themes/themes";
import { LogoImages } from "../../../../Common/Logo/logo";

const theme = themeFooter;

class BootstrapInput extends React.Component {
    render() {
        return null;
    }
}

BootstrapInput.propTypes = {
    id: PropTypes.string,
    defaultValue: PropTypes.string
};

export function Footer() {

    function Item(props) {
        const { sx, ...other } = props;
        return (
            <Box
                sx={{
                    p: 1,
                    m: 1,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    ...sx,
                }}
                {...other}
            />
        );
    }

    Item.propTypes = {
        sx: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
            ),
            PropTypes.func,
            PropTypes.object,
        ]),
    };

    return(
        <div className="container">
            <ThemeProvider theme={theme}>
                <AppBar position="static">
                    {/*<AppBar position="static" color="primary" sx={{ bgcolor: "#00224f" }}>
                        <Container maxWidth="md">
                            <div style={{textAlign: "center", margin: "30px"}}>
                                <div>Сэкономьте время и деньги!</div>
                                <div>Подпишитесь, и мы вышлем вам лучшие предложения</div>
                                <div style={{margin: "5px"}}>
                                    <input style={{height: "36px", borderRadius: "4px", border: 0}} type="text" size="40" />
                                    <Button sx={{marginLeft: "5px"}} variant="contained">Подписаться</Button>
                                </div>
                            </div>
                        </Container>
                    </AppBar>
                    <AppBar position="static" color="primary" sx={{ bgcolor: "#003580" }}>
                        <Container maxWidth="md" sx={{ bgcolor: "#003580" }}>
                            <Toolbar sx={{ bgcolor: "#003580", alignItems: "center", justifyContent: "center" }}>
                                <Button  sx={{ color: "white", borderColor: "white" }}
                                         variant="outlined"
                                         href={
                                         window.location.protocol +
                                         "//" +
                                         "join" +
                                         "." +
                                         window.location.host}>Зарегистрировать свой объект</Button>
                            </Toolbar>
                        </Container>
                    </AppBar>
                    <div style={{ width: '100%', height: 1 }}>
                    </div>
                    <AppBar position="static" color="primary" sx={{ bgcolor: "#003580" }}>
                        <Container>
                            <Toolbar>
                                <Stack spacing={2} direction="row">
                                    <a
                                        className={s.footerTopLink}
                                        href={
                                        window.location.protocol +
                                        "//" +
                                        "join" +
                                        "." +
                                        window.location.host
                                    }>Управлять бронированиями</a>
                                    <Link className={s.footerTopLink} to="/help">Связаться с нами</Link>
                                    <Link className={s.footerTopLink} to="/affiliates">Программа для аффилиатов</Link>
                                </Stack>
                            </Toolbar>
                        </Container>
                    </AppBar>*/}
                    <div style={{ width: '100%', textDecoration: "none"}}>
                        <Container fixed>
                            <Box
                                sx={{ display: 'flex', p: 1, borderRadius: 1, justifyContent: "space-between" }}
                            >
                                {/*<Item sx={{ width: '20%' }}>
                                    <Stack spacing={1} direction="column">
                                        <Link className={s.Link} to="/country">Страны</Link>
                                        <Link className={s.Link} to="/region">Регионы</Link>
                                        <Link className={s.Link} to="/city">Города</Link>
                                        <Link className={s.Link} to="/district">Района</Link>
                                        <Link className={s.Link} to="/landmark">Ориентиры</Link>
                                    </Stack>
                                </Item>*/}
                                {/*<Item sx={{ width: '25%' }}>
                                    <Stack spacing={1} direction="column">
                                        <Link className={s.Link} to="/about">О kvartiranasutki</Link>
                                        <Link className={s.Link} to="/home">Дома и апартаменты</Link>
                                        <Link className={s.Link} to="/apartments">Апартаменты/квартиры</Link>
                                        <Link className={s.Link} to="/hostels">Отели</Link>
                                    </Stack>
                                </Item>*/}
                                <Item>
                                    <Link className={s.Link} to="/contact-us">Связаться с нами</Link>
                                    {/*<Link className={s.Link} to="/accommodations">Уникальное жилье</Link>
                                    <Link className={s.Link} to="/articles">Статьи</Link>*/}

                                </Item>
                                <Item>
                                    <Link className={s.Link} to="/terms">Правила публикации объявлений</Link>
                                </Item>
                                <Item>
                                    {/*zxc<Link className={s.Link} to="/about">О kvartiranasutki</Link>*/}
                                    {/*zxc<Link className={s.Link} to="/help">Связаться с нами</Link>*/}
                                    {/*<Link className={s.Link} to="/partner">Центр помощи партнерам</Link>
                                    <Link className={s.Link} to="/sustainability">Устойчивое развитие</Link>
                                    <Link className={s.Link} to="/trust-and-safity">Центр знаний по безопасности</Link>*/}
                                    {/*zxc<Link className={s.Link} to="/terms">Правила и условия</Link>*/}
                                    {/*<Link className={s.Link} to="/complaints">Разрешрения споров</Link>*/}
                                    <Link className={s.Link} to="/how-we-work">Правила бронирования</Link>
                                    {/*<Link className={s.Link} to="/privacy">Положение о конфиденциальности и cookie</Link>
                                    <Link className={s.Link} to="/contact-us">Корпоративные контакты</Link>
                                    <Link className={s.Link} to="/privacy_policy">Политика конфиденциальности</Link>*/}
                                </Item>
                                <Item>
                                    <Link className={s.Link} to="/public-contract">Публичный договор</Link>
                                </Item>
                                <Item>
                                    <Link className={s.Link} to="/payment">Оплата</Link>
                                </Item>
                            </Box>
                        </Container>
                    </div>
                    <br/>
                    <div>
                        <Container fixed>
                            <div style={{ textAlign: "center", fontSize: "12px", margin:"10px" }}>
                                <div style={{ marginBottom: '2px' }}>
                                    ООО «КонтактЛайнСервис»
                                    Юридический адрес: РБ, г. Брест, ул. Янки Купалы 100/1, к. 3.
                                    Зарегистрировано Администрацией Ленинского района г. Бреста 12.03.2012 г.
                                    УНП 291080945
                                </div>
                                <div>
                                    Чтобы связаться с администрацией сайта, наберите номер <a href="tel:+375445558775" target="_blank" rel="noreferrer">+375445558775</a> <a href="mailto:info@kvartiranasutki.com" target="_blank" rel="noreferrer">info@kvartiranasutki.com</a>
                                </div>
                                <div>Часы работы: пн—пт, 9:00—18:00 МСК</div>

                                <div style={{ marginTop: '4px' }}>Copyright © 2022–{new Date().getFullYear()} Kvartiranasutki.com™. Все права защищены.</div>
                                {/*<div style={{color: "#6b6b6b", fontSize: "12px"}}>kvartira-na-sutki.com — лидер СНГ в сфере онлайн-туризма и сопутствующих услуг</div>
                                <div style={{color: "#6b6b6b", fontSize: "12px"}}>Администрация сайта: 375445558775 (А1), +375339933323 (МТС)</div>*/}
                                <div><LogoImages /></div>
                            </div>
                        </Container>
                    </div>
                </AppBar>
            </ThemeProvider>
        </div>
    )
}
