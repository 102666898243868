const initialState = {
    users: null,
    user: null,

    id: null,
    created_at: null,
    name: null,
    phones: null,
    permissions: [],
    roles: null,
    email: null,
    email_verified: null,
    telegram: null,
    telegramSubscribeLink: null,
    statusTelegram: null,
    statusEmail: null,
    chats: null,
    chat: null,
    user_profile: null,
}
//TODO
export default function user(state= initialState, action) {
    switch (action.type) {
        case "GET_USERS":
            return {...state, users: action.payload};
        case "GET_USER":
            return {
                ...state,
                id: action.payload.id,
                created_at: action.payload.created_at,
                user_profile: {...state.user_profile, ...action.payload.user_profile},
                email: action.payload.email,
                email_verified: action.payload.email_verified,
                user: {...state.user, phones: action.payload.phones},
                roles: action.payload.roles,
                permissions: action.payload.permissions,
            };
        case "SET_FIRST_NAME":
            return {...state, firstName: action.payload};
        case "SET_EMAIL":
            return {...state, email: action.payload};
        case "SET_TELEGRAM":
            return {...state, telegram: action.payload};
        case "SET_PHONES":
            return {...state, user: {...state.user, phones: action.payload}};
        case "SET_TELEGRAM_SUBSCRIBE_LINK":
            return {...state, telegramSubscribeLink: action.payload};
        case "SET_STATUS_EMAIL":
            return {...state, statusEmail: action.payload};
        case "SET_STATUS_TELEGRAM":
            return {...state, statusTelegram: action.payload};
        case "GET_CHATS":
            return {...state, chats: action.payload};
        case "GET_CHAT":
            return {...state, chat: action.payload};
        case "SET_ID":
            return {...state, id: action.payload};
        case "SET_PERMISSIONS":
            return {...state, permissions: action.payload};
        case "GET_USER_PROFILE":
            return {...state, user_profile: action.payload};
        case "SET_USER_PROFILE_FIRST_NAME":
            return {...state, user_profile: {...state.user_profile, first_name: action.payload}};
        case "SET_USER_PROFILE_LAST_NAME":
            return {...state, user_profile: {...state.user_profile, last_name: action.payload}};
        case "SET_USER_PROFILE_BIRTHDATE":
            return {...state, user_profile: {...state.user_profile, birthdate: action.payload}};
        case "SET_USER_PROFILE_GENDER":
            return {...state, user_profile: {...state.user_profile, gender: action.payload}};
        case "SET_USER_PROFILE_LOCATION":
            return {...state, user_profile: {...state.user_profile, location: action.payload}};
        case "SET_USER_PROFILE_PHOTO":
            return {...state, user_profile: {...state.user_profile, photo: action.payload}};
        case "GET_USER_PHONES":
            return {...state, user: {...state.user, phones: action.payload}};
        default: return state;
    }
};