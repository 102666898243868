export const getPropertyType = payload => {
    return {
        type: "GET_PROPERTY_TYPE",
        payload: payload
    }
}

export const getPropertyTypes = payload => {
    return {
        type: "GET_PROPERTY_TYPES",
        payload: payload
    }
}

export const setName = payload => {
    return {
        type: "SET_NAME",
        payload: payload
    }
}

