export const getCurrencies = payload => {
    return {
        type: "GET_CURRENCIES",
        payload: payload
    }
}

export const getCurrency = payload => {
    return {
        type: "GET_CURRENCY",
        payload: payload
    }
}

export const setIsoCode = payload => {
    return {
        type: "SET_ISO_CODE",
        payload: payload
    }
}

