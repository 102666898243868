const initialState = {
    propertyTypes: null,
    propertyType: null,
    name: null,
}
//TODO
export default function propertyType(state= initialState, action) {
    switch(action.type){
        case "GET_PROPERTY_TYPE":
            return {...state, propertyType: action.payload};
        case "GET_PROPERTY_TYPES":
            return {...state, propertyTypes: action.payload};
        case "SET_NAME":
            return {...state, name: action.payload};
        default: return state;
    }
};