import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { updateUserProfile } from "../../../../../../Requests/CommonRequests/commonRequests";

export function Gender(props) {
    const handleSubmit = () => {
        updateUserProfile(props)
            .then(res => {
                props.handleClose()
            })
    };

    return (
        <>
            <div>
                <TextField
                    margin="normal"
                    select
                    defaultValue={props.user.user_profile.gender}
                    required
                    label="Пол"
                    name="user[gender]"
                    id="user[gender]"
                    onChange={(event) => {props.userActions.setUserProfileGender(event.target.value)}}
                    style = {{ width: 244 }}
                >
                    {[{id: 'male', name: 'Мужской'}, {id: 'female', name: 'Женский'}].map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleSubmit()}
            >
                Сохранить
            </Button>
        </>
    )
}