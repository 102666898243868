const initialState = {
    dashboard1: null,
    dashboard2: null,
}

export default function role(state= initialState, action) {
    switch (action.type) {
        case "GET_DASHBOARD_1":
            return {...state, dashboard1: action.payload};
        case "GET_DASHBOARD_2":
            return {...state, dashboard2: action.payload};
        default: return state;
    }
};
