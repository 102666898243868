export const getAnswerTimeIndex = payload => {
    return {
        type: "GET_ANSWER_TIME_INDEX",
        payload: payload
    }
}

export const getCalendarUpdateTimeIndex = payload => {
    return {
        type: "GET_CALENDAR_UPDATE_TIME_INDEX",
        payload: payload
    }
}