import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MenuMaterial from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";

import { themeMenu } from "../../../../../../Themes/themes";
import logo from "../../../../../../Img/logo.png";

const theme = themeMenu;

export function Menu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('token')
            }
        };

        fetch(`${process.env.REACT_APP_REST_API}/auth/logout`, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    document.cookie = `token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=.${window.location.protocol === 'https:' ? process.env.REACT_APP_DOMAIN : process.env.REACT_APP_LOCAL_DOMAIN};path=/`;
                    window.location = window.location.protocol === 'https:' ? process.env.REACT_APP_BASIC : process.env.REACT_APP_LOCAL_BASIC;
                },
                (error) => {
                    console.log(error)
                }
            )
    }

    return(
        <nav id="navPanel">
            <ThemeProvider theme={theme}>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                <Button color="inherit">
                                    <Link
                                        key={"Main"}
                                        to={'/'}
                                    >
                                        <img style={{ height: '22px' }} src={logo} alt='' />
                                    </Link>
                                </Button>
                            </Typography>
                            <Button  sx={{ color: '#000000' }}
                                     color="secondary"
                                     key={"host"}
                                     >
                                <Link
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0
                                    }}
                                    to="/host"
                                />
                                К ПРИЁМУ ГОСТЕЙ
                            </Button>
                            <div>
                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                    <Tooltip title="Account settings">
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{ ml: 2 }}
                                            aria-controls={open ? 'Account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <Avatar sx={{ width: 32, height: 32 }} src={props.user.user_profile?.photo?.url}>{props.user.user_profile?.first_name?.at(0)}</Avatar>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <MenuMaterial
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgColor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem>
                                        <Link
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0
                                            }}
                                            to="inbox"
                                        />
                                        Сообщения
                                    </MenuItem>
                                    <MenuItem>
                                        <Link
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0
                                            }}
                                            to="wishlists"
                                        />
                                        Избранные
                                    </MenuItem>
                                    <MenuItem>
                                        <Link
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0
                                            }}
                                            to="reservations"
                                        />
                                        Бронирования
                                    </MenuItem>
                                    <MenuItem>
                                        <Link
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0
                                            }}
                                            to="/guest/progress/reviews"
                                        />
                                        Отзывы
                                    </MenuItem>
                                    <MenuItem>
                                        <Link
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0
                                            }}
                                            to="account-settings"
                                        />
                                        Аккаунт
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={handleLogout}>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        Выйти
                                    </MenuItem>
                                </MenuMaterial>
                            </div>
                        </Toolbar>
                    </AppBar>
                </Box>
            </ThemeProvider>
        </nav>
    )
}
