import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import Paper from "@mui/material/Paper";

export function Menu() {
    const [value, setValue] = React.useState('sign-in');
    const [isVisible, setIsVisible] = useState(true);
    const navigate = useNavigate();

    /*useEffect(() => {
        console.log('zxc')
        setIsVisible(false)
        setInterval(() => {
            setIsVisible(
                document.hasFocus() &&
                document.activeElement !== null &&
                document.activeElement !== document.body &&
                document.activeElement !== document.documentElement
            )

            console.log(document.hasFocus() &&
                document.activeElement !== null &&
                document.activeElement !== document.body &&
                document.activeElement !== document.documentElement)
        }, 2000)

        //document.getElementByTagName('input')
    }, [() =>{document.hasFocus()}])*/

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return(
        <Paper sx={{ position: 'fixed !important', bottom: 0, width: '100%', height: '56px', zIndex: 999, visibility: isVisible ? 'hidden' : 'visible' }} elevation={3}>
            <BottomNavigation showLabels value={value} onChange={handleChange}>
                <BottomNavigationAction
                    label={<div style={{ fontSize: '11px' }}>Поиск</div>}
                    value="search"
                    onClick={() => navigate('/')}
                    icon={<SearchIcon />}
                />
                <BottomNavigationAction
                    label={<div style={{ fontSize: '11px' }}>Войти</div>}
                    value="sign-in"
                    onClick={() => navigate('/login')}
                    icon={<AccountCircleIcon />}
                />
            </BottomNavigation>
        </Paper>
    )
}