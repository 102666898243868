// Core
import { useState } from 'react';
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

// UI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuMaterial from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { ThemeProvider } from "@mui/material/styles";

// Tools
import { themeMenu } from "../../../../../Themes/themes";
import logo from "../../../../../Img/logo.png";

const theme = themeMenu;

export function Menu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        };

        fetch(`${process.env.REACT_APP_REST_API}/auth/logout`, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    document.cookie = `token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=.${window.location.protocol === 'https:' ? process.env.REACT_APP_DOMAIN : process.env.REACT_APP_LOCAL_DOMAIN};path=/`;
                    window.location.reload();
                },
                (error) => {
                    console.log(error)
                    document.cookie = `token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=.${window.location.protocol === 'https:' ? process.env.REACT_APP_DOMAIN : process.env.REACT_APP_LOCAL_DOMAIN};path=/`;
                    window.location.reload();
                }
            )
    }

    return(
        <nav id="navPanel">
            <ThemeProvider theme={theme}>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" spacing={2}>
                        <Toolbar spacing={2}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                <Button color="inherit" aria-label="Главная">
                                    <Link
                                        key={"Basic"}
                                        aria-label="Главная"
                                        to={'/'}
                                    >
                                        <img style={{ height: '22px' }} src={logo} alt='' />
                                    </Link>
                                </Button>
                            </Typography>

                            {Cookies.get('token') ? (
                                <>
                                    {props.user?.permissions?.find(item => item.name === 'admin-panel') ? (
                                        <Link to={'/admin/dashboard'}>
                                            <Button  sx={{ color: '#000000' }}
                                                     color="inherit"
                                                     key={"admin"}
                                                     >
                                                Административная панель
                                            </Button>
                                        </Link>
                                    ) : ''}
                                    <Link to={props.user?.permissions?.find(item => item.name === 'extranet')
                                                ? "/host/today/leaving"
                                                : "/host/properties/create"
                                        }
                                    >
                                        <Button sx={{ color: '#000000' }}
                                                 color="inherit"
                                                 key={"host"}
                                                 >
                                            К приёму гостей
                                        </Button>
                                    </Link>
                                    <div>
                                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                            <Tooltip title="Account settings">
                                                <IconButton
                                                    onClick={handleClick}
                                                    size="small"
                                                    sx={{ ml: 2 }}
                                                    aria-controls={open ? 'Account-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                >
                                                    {<Avatar sx={{ width: 32, height: 32 }} alt={'Аватар'} src={props.user.user_profile?.photo?.url}>{props.user?.user_profile?.first_name ? props.user?.user_profile?.first_name[0] : ''}</Avatar>}
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <MenuMaterial
                                            anchorEl={anchorEl}
                                            id="account-menu"
                                            open={open}
                                            onClose={handleClose}
                                            onClick={handleClose}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    '& .MuiAvatar-root': {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    '&:before': {
                                                        content: '""',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgColor: 'background.paper',
                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                        zIndex: 0,
                                                    },
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuItem>
                                                <Link
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 0
                                                    }}
                                                    key={"inbox"}
                                                    to={'/guest/inbox'}
                                                />
                                                Сообщения
                                            </MenuItem>
                                            <MenuItem>
                                                <Link
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 0
                                                    }}
                                                    key={"reservations"}
                                                    to={'/guest/reservations'}
                                                />
                                                Поездки
                                            </MenuItem>
                                            <MenuItem>
                                                <Link
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 0
                                                    }}
                                                    key={"wishlists"}
                                                    to={'/guest/wishlists'}
                                                />
                                                Избранные
                                            </MenuItem>
                                            <MenuItem>
                                                <Link
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 0
                                                    }}
                                                    key={"wishlists"}
                                                    to={'/guest/progress/reviews'}
                                                />
                                                Отзывы
                                            </MenuItem>
                                            <MenuItem>
                                                <Link
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 0
                                                    }}
                                                    key={"Account1"}
                                                    to={'/guest/account-settings'}
                                                />
                                                Аккаунт
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem onClick={handleLogout}>
                                                <ListItemIcon>
                                                    <Logout fontSize="small" />
                                                </ListItemIcon>
                                                Выйти
                                            </MenuItem>
                                        </MenuMaterial>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <Link to={'login'}>
                                        <Button  sx={{ color: '#000000' }}
                                                 key={"Account4"}
                                                 >
                                            Зарегистрироваться
                                        </Button>
                                    </Link>
                                    <Link to={'login'}>
                                        <Button  sx={{ color: '#000000' }}
                                                 key={"Account5"}
                                                 >
                                            Войти в аккаунт
                                        </Button>
                                    </Link>
                                </div>
                            )}
                        </Toolbar>
                    </AppBar>
                </Box>
            </ThemeProvider>
        </nav>
    )
}
