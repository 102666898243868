// Core
import { bindActionCreators } from 'redux';
import { connect } from "react-redux"

// Tools
import { App } from "../../components/app";
import * as ActionProperty from "../actions/actionProperty";
import * as ActionUrlRequest from "../actions/actionUrlRequest";
import * as ActionPropertyType from "../actions/actionPropertyType";
import * as ActionUser from "../actions/actionUser";
import * as ActionCountryRegister from "../actions/actionCountryRegister";
import * as ActionBooking from "../actions/actionBooking";
import * as ActionPropertyFavourite from "../actions/actionPropertyFavourite";
import * as ActionCurrency from "../actions/actionCurrency";
import * as ActionPropertyRegion from "../actions/actionRegion";
import * as ActionLog from "../actions/actionLog";
import * as ActionRole from "../actions/actionRole";
import * as ActionPermission from "../actions/actionPermission";
import * as ActionAmenity from "../actions/actionAmenity";
import * as ActionAmenityCategory from "../actions/actionAmenityCategory";
import * as ActionRatingCoefficient from "../actions/actionRatingCoefficient";
import * as ActionReview from "../actions/actionReview";
import * as ActionReviewScoreCategory from "../actions/actionReviewScoreCategory";
import * as ActionBookingFeedback from "../actions/actionBookingFeedback";
import * as ActionWidget from "../actions/actionWidget";
import * as ActionInternalSystem from "../actions/actionInternalSystem";
import * as ActionKeyStorageItem from "../actions/actionKeyStorageItem";
import keyStorageItem from "../reducers/keyStorageItem";

const mapStateToProps = ({ property, urlRequest, propertyType, user, countryRegister, propertyFavourite, booking, region, currency, log, role, permission, amenity, amenityCategory, ratingCoefficient, review, reviewScoreCategory, bookingFeedback, widget, internalSystem, keyStorageItem }) =>
    ({ property, urlRequest, propertyType, user, countryRegister, propertyFavourite, booking, region, currency, log, role, permission, amenity, amenityCategory, ratingCoefficient, review, reviewScoreCategory, bookingFeedback, widget, internalSystem, keyStorageItem })

const matchDispatchToProps = dispatch => ({
    propertyActions: bindActionCreators(ActionProperty, dispatch),
    urlRequestActions: bindActionCreators(ActionUrlRequest, dispatch),
    propertyTypeActions: bindActionCreators(ActionPropertyType, dispatch),
    userActions: bindActionCreators(ActionUser, dispatch),
    countryRegisterActions: bindActionCreators(ActionCountryRegister, dispatch),
    bookingActions: bindActionCreators(ActionBooking, dispatch),
    propertyFavouriteActions: bindActionCreators(ActionPropertyFavourite, dispatch),
    currencyActions: bindActionCreators(ActionCurrency, dispatch),
    regionActions: bindActionCreators(ActionPropertyRegion, dispatch),
    logActions: bindActionCreators(ActionLog, dispatch),
    roleActions: bindActionCreators(ActionRole, dispatch),
    permissionActions: bindActionCreators(ActionPermission, dispatch),
    amenityActions:  bindActionCreators(ActionAmenity, dispatch),
    amenityCategoryActions: bindActionCreators(ActionAmenityCategory, dispatch),
    ratingCoefficientActions: bindActionCreators(ActionRatingCoefficient, dispatch),
    reviewActions: bindActionCreators(ActionReview, dispatch),
    reviewScoreCategoryActions: bindActionCreators(ActionReviewScoreCategory, dispatch),
    bookingFeedbackActions: bindActionCreators(ActionBookingFeedback, dispatch),
    widgetActions: bindActionCreators(ActionWidget, dispatch),
    internalSystemActions: bindActionCreators(ActionInternalSystem, dispatch),
    keyStorageItemActions: bindActionCreators(ActionKeyStorageItem, dispatch)
})

export default connect(mapStateToProps,matchDispatchToProps)(App)
