import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { updateUserProfile } from "../../../../../../Requests/CommonRequests/commonRequests";

export function Name(props) {
    const handleSubmit = () => {
        updateUserProfile(props)
            .then(res => {
                props.handleClose()
            })
    };

    return(
        <>
            <div style={{ display: 'flex' }}>
                <TextField
                    margin="normal"
                    defaultValue={props.user.user_profile.first_name}
                    required
                    label="Имя"
                    name="firstName"
                    id="firstName"
                    onBlur={(event) => {props.userActions.setUserProfileFirstName(event.target.value)}}
                    style = {{ width: 244, paddingRight: '20px' }}
                />

                <TextField
                    margin="normal"
                    defaultValue={props.user.user_profile.last_name}
                    required
                    label="Фамилия"
                    name="lastName"
                    id="lastName"
                    onBlur={(event) => {props.userActions.setUserProfileLastName(event.target.value)}}
                    style = {{ width: 244 }}
                />
            </div>

            <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleSubmit()}
            >
                Сохранить
            </Button>
        </>
    )
}