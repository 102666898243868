import React from "react"
import { Link } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { themeMenu } from '../../../../../../Themes/themes';
import { ThemeProvider } from "@mui/material/styles";

import logo from "../../../../../../Img/logo.png";

const theme = themeMenu;

export function Menu() {
    return(
        <nav>
            <ThemeProvider theme={theme}>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                <Button color="inherit">
                                    <Link
                                        key={"Account"}
                                        to={'/'}
                                    >
                                        <img style={{ height: '22px' }} src={logo} alt='logo' />
                                    </Link>
                                </Button>
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </Box>
            </ThemeProvider>
        </nav>
    )
}
