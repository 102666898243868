import { addDays, format } from "date-fns";
import lodash from 'lodash';
import { AdvancedFetch, requestOptions } from "../../Utils/Helpers/Fetch/advancedFetch";

export const getCurrencies = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/currencies`)
        .then(
            (result) => {
                props.currencyActions.getCurrencies(result)
            },
            (error) => {
               console.log(error)
            }
        )
}

export const getRegions = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/regions`)
        .then(
            (result) => {
                props.regionActions.getRegions(result)
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getPropertyTypes = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/property-types`)
        .then(
            (result) => {
                props.propertyTypeActions.getPropertyTypes(result)
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getMailInfo = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user/notifications/mail/notify-status`, requestOptions)
        .then(
            (result) => {
                props.userActions.setStatusEmail(result?.status)
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getTelegramInfo = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user/notifications/telegram/notify-status`, requestOptions)
        .then(
            (result) => {
                props.userActions.setStatusTelegram(result?.status)
                props.userActions.setTelegram(result?.status)
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getTelegramSubscribeLink = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user/notifications/telegram/subscribe-link`, requestOptions)
        .then(
            (result) => {
                props.userActions.setTelegramSubscribeLink(result)
            },
            (error) => {
                console.log(error)
            }
        )
}

export const updateStatusTelegram = (props, status) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user/notifications/telegram/update`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify({
            'status': status,
        })
    })
}

const getLastBooking = (props, startDate, endDate) => {
    const req = {
        'limit': 1,
        'sort': '-created_at',
        'property_id': `eq:${props.property.id}`,
        'start_date[]': startDate,
        'end_date[]': endDate,
    };

    let params = new URLSearchParams(req);

    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/secure/bookings?` + params.toString(), requestOptions)
}

const createChatChannelChat = (props, lastBooking) => {
    let searchParams = new URLSearchParams(window.location.search)

    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/chat-channels`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify({
            'property_id': props.property.id,
            'with_user_id': props.property.user.id,
            'details': lastBooking
                ?
                {
                    booking: {
                        start_date: lastBooking.start_date,
                        end_date: lastBooking.end_date,
                        guests: {
                            adults: lastBooking.guests
                        },
                    }
                }
                :
                {
                    booking: {
                        start_date: searchParams.get('startDate') || format(new Date(), 'yyyy-MM-dd'),
                        end_date: searchParams.get('endDate') || format(addDays(new Date(), 1), 'yyyy-MM-dd'),
                        guests: {
                            adults: searchParams.get('adults') || 1,
                        },
                    }
                },
        })
    })
}

export async function createChatChannel(props, message, startDate, endDate) {
    const createChatChannelMessage = (id, message) => {
        return AdvancedFetch(`${process.env.REACT_APP_REST_API}/chat-channels/${id}/chat-messages`, {
            ...requestOptions,
            method: 'POST',
            body: JSON.stringify({
                message: message
            })
        })
    }

    let lastBooking = await getLastBooking(props, startDate, endDate);
    let chat = await createChatChannelChat(props, lastBooking[0]);
    if (!chat.id) return chat
    let result = await createChatChannelMessage(chat.id, message);

    return result
}

export const getChatMessages = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/chat-channels/${id}/chat-messages`, requestOptions)
        .then((res) => {
            if (res.status > 400) {
                throw new Error('404 Page Not Found')
            }

            return res;
        })
        .then(
            (result) => {
                props.userActions.getChat(result)
            },
            (error) => {
                console.log(error)
                throw new Error('404 Page Not Found')
            }
        )
}

export function getFirstChatMessage(props, id, limit = '', sort = '') {
    const req = {
        limit: limit,
        sort: sort,
    };

    let params = new URLSearchParams(req);
    let keysForDel = [];
    params.forEach((value, key) => { // never reaches `c`
        if (value === '' || value === null) {
            keysForDel.push(key);
        }
    });

    keysForDel.forEach(key => {
        params.delete(key);
    });

    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/chat-channels/${id}/chat-messages?` + params.toString(), requestOptions)
        .then(
            (result) => {
                let upd = [...props.user.chats]
                result.length && props.user.chats.map((item, index) => {
                    if (item.id === result[0].chat_channel_id) {
                        upd[index]['firstMessage'] = result[0];
                    }
                })

                props.userActions.getChats(upd)
            },
            (error) => {
                console.log(error)
            }
        )
}

export const createChatMessage = (props, id, message) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/chat-channels/${id}/chat-messages`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify({
            message: message
        })
    })
}

export const getUserId = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user/identify`, requestOptions)
        .then(res => props.userActions.setId(res.id))
}

export const getUserPermissions = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user/identify`, requestOptions)
        .then(res => props.userActions.setPermissions(res.permissions))
}

export const getUser = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user/identify`, requestOptions)
        .then(res => props.userActions.getUser(res))
        .then(res => {return res})
}

export const getCountryRegisters = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/country-registers`, requestOptions)
        //.then((res) => console.log(res))
        .then(
            (result) => {
                props.countryRegisterActions.getCountryRegisters(result)
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getUserExists = (props, login) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/auth/available-to-login`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify({
            login: login
        })
    })
}

export const verifyCode = (body) => {
    return fetch(`${process.env.REACT_APP_REST_API}/phone-verification/verify`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export const sendVerifyCode = (body) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/phone-verification/send-code`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export const getUserProfile = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user-profiles`, requestOptions)
        .then(res => props.userActions.getUserProfile(res))
}

export const updateUserProfile = (props) => {
    let body = {
        photo_path: props.user.user_profile.photo?.path,
        first_name: props.user.user_profile.first_name,
        last_name: props.user.user_profile.last_name,
        gender: props.user.user_profile.gender,
        birthdate: props.user.user_profile.birthdate,
        location: props.user.user_profile.location,
    }

    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user-profiles`, {
        ...requestOptions,
        method: 'PATCH',
        body: JSON.stringify(lodash.omitBy(body, lodash.isNil))
    })
}

export const getPropertyFavourites = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/property-favorites`, requestOptions)
        .then(res => props.propertyFavouriteActions.getPropertyFavourites(res))
}

export const createPropertyFavourite = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/properties/${id}/favorite`, {
        ...requestOptions,
        method: 'POST',
    })
}

export const deletePropertyFavourite = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/properties/${id}/favorite`, {
        ...requestOptions,
        method: 'DELETE',
    })
}

export const getUserEmailResend = () => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user/email-resend`, requestOptions)
}

export const getUserEmailVerify = (expires, signature) => {
    const req = {
        'expires': expires,
        'signature': signature,
    };

    let params = new URLSearchParams(req);

    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user/email-verify?` + params.toString(), requestOptions)
}

export const getGeoInfo = () => {
    return AdvancedFetch('https://ipapi.co/json/')
}

export const getAmenities = (props, customParams = '') => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/amenities${customParams}`, requestOptions)
        .then(
            (result) => {
                props.amenityActions.getAmenities(result);
            },
            (error) => {
                console.log(error);
            }
        )
}

export const getCategoryAmenities = (props, customParams = '') => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/amenity-categories`, requestOptions)
        .then(
            (result) => {
                props.amenityCategoryActions.getAmenityCategories(result);
            },
            (error) => {
                console.log(error);
            }
        )
}

export const getUserPhones = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user-phones`, requestOptions)
        .then(
            (result) => {
                props.userActions.getUserPhones(result);
            },
            (error) => {
                console.log(error);
            }
        )
}

export const deleteUserPhone = (id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user-phones/${id}`, {
        ...requestOptions,
        method: 'DELETE',
    })
}

export const addUserPhone = (body) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user-phones`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export const updateUserPhone = (props, id, body) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user-phones/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify(body)
    })
}

export const updateUserEmail = (props, body) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/user`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify(body)
    })
}

export const getReviewScoreCategories = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/review-score-categories`, requestOptions)
        .then(
            (result) => {
                props.reviewScoreCategoryActions.getReviewScoreCategories(result);
                return result;
            },
            (error) => {
                console.log(error);
            }
        )
}