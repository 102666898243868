export const getCountryRegisters = payload => {
    return {
        type: "GET_COUNTRY_REGISTERS",
        payload: payload
    }
}

export const getCountryRegister = payload => {
    return {
        type: "GET_COUNTRY_REGISTER",
        payload: payload
    }
}