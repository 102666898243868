const initialState = {
    regions: null,
    region: null,
    name: null,
    parentId: null,
    lat: null,
    lng: null,
}
//TODO
export default function region(state= initialState, action) {
    switch(action.type){
        case "GET_REGION":
            return {...state,
                name: action.payload.name,
                parentId: action.payload.parent_id,
                lat: action.payload.lat,
                lng: action.payload.lng,
            };
        case "GET_REGIONS":
            return {...state, regions: action.payload};
        case "SET_NAME":
            return {...state, name: action.payload};
        case "SET_PARENT_ID":
            return {...state, parentId: action.payload};
        case "SET_LAT":
            return {...state, lat: action.payload};
        case "SET_LNG":
            return {...state, lng: action.payload};
        default: return state;
    }
};