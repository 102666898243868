import PropTypes from 'prop-types';
import React from "react";

import styled from '@emotion/styled';
import { AppBar, Avatar, Badge, Box, IconButton, Toolbar, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import { Bell as BellIcon } from '../Icons/bell';
import { UserCircle as UserCircleIcon } from '../Icons/user-circle';
import { Users as UsersIcon } from '../Icons/users';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
    const { user_profile, onSidebarOpen, ...other } = props;

    return (
        <>
            <DashboardNavbarRoot
                sx={{
                    left: {
                        lg: 280
                    },
                    width: {
                        lg: 'calc(100% - 280px)'
                    }
                }}
                {...other}>
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 64,
                        left: 0,
                        px: 2
                    }}
                >
                    <IconButton
                        onClick={onSidebarOpen}
                        sx={{
                            display: {
                                xs: 'inline-flex',
                                lg: 'none'
                            }
                        }}
                    >
                        <MenuIcon fontSize="small" />
                    </IconButton>
                    <Tooltip title="Search">
                        <IconButton sx={{ ml: 1 }}>
                            <SearchIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Box sx={{ flexGrow: 1 }} />
                    <Tooltip title="Contacts">
                        <IconButton sx={{ ml: 1 }}>
                            <UsersIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Notifications">
                        <IconButton sx={{ ml: 1 }}>
                            <Badge
                                badgeContent={4}
                                color="primary"
                                variant="dot"
                            >
                                <BellIcon fontSize="small" />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    <Avatar
                        sx={{
                            height: 40,
                            width: 40,
                            ml: 1
                        }}
                        src={user_profile?.photo?.url}
                    >
                        <UserCircleIcon fontSize="small" />
                    </Avatar>
                </Toolbar>
            </DashboardNavbarRoot>
        </>
    );
};

DashboardNavbar.propTypes = {
    onSidebarOpen: PropTypes.func
};