const initialState = {
    amenity: null,
    amenities: null,
}

export default function amenity(state= initialState, action) {
    switch(action.type) {
        case "GET_AMENITY":
            return {...state, amenity: action.payload};
        case "GET_AMENITIES":
            return {...state, amenities: action.payload};
        case "SET_NAME":
            return {...state, amenity: {...state.amenity, name: action.payload}};
        case "SET_CATEGORY_ID":
            return {...state, amenity: {...state.amenity, category_id: action.payload}};
        case "SET_IS_ACTIVE":
            return {...state, amenity: {...state.amenity, is_active: action.payload}};
        case "SET_IS_POPULAR":
            return {...state, amenity: {...state.amenity, is_popular: action.payload}};
        case "SET_ICON_PATH":
            return {...state, amenity: {...state.amenity, icon_path: action.payload}};
        default: return state;
    }
};