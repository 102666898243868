const initialState = {
    logs: null,
    log: null,
}

export default function log(state = initialState, action) {
    switch(action.type){
        case "GET_LOGS":
            return {...state, logs: action.payload};
        case "GET_LOG":
            return {...state, log: action.payload};
        default: return state;
    }
};