import mtBank from "../../../Img/mtbank.png";
import googlePay from "../../../Img/google-pay-mark.png";
import visaMasterCardBelCard from "../../../Img/visa-masterCard-belCard.png";
import mirPay from "../../../Img/mir-pay-mark.png"

export function LogoImages() {
    return (
        <div style={{ display: 'flex', direction: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <img style={{ height: '18px', paddingTop: '4px' }} src={mtBank} alt='mtBank' />
            <img style={{ height: '30px', marginRight: '-10px' }} src={mirPay} alt='mirPay' />
            <img style={{ height: '30px' }} src={visaMasterCardBelCard} alt='visaMasterCardBelCard' />
            <img style={{ height: '18px' }} src={googlePay} alt='googlePay' />
        </div>
    )
}