export const getAmenities = payload => {
    return {
        type: "GET_AMENITIES",
        payload: payload
    }
}

export const getAmenity = payload => {
    return {
        type: "GET_AMENITY",
        payload: payload
    }
}

export const setName = payload => {
    return {
        type: "SET_NAME",
        payload: payload
    }
}

export const setCategoryId = payload => {
    return {
        type: "SET_CATEGORY_ID",
        payload: payload
    }
}

export const setIsActive = payload => {
    return {
        type: "SET_IS_ACTIVE",
        payload: payload
    }
}

export const setIsPopular = payload => {
    return {
        type: "SET_IS_POPULAR",
        payload: payload
    }
}

export const setIconPath = payload => {
    return {
        type: "SET_ICON_PATH",
        payload: payload
    }
}