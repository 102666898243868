const initialState = {
    keyStorageItems: null,
}

export default function booking(state= initialState, action) {
    switch(action.type){
        case "GET_KEY_STORAGE_ITEMS":
            return {...state, keyStorageItems: action.payload};
        default: return state;
    }
};