export const getReviewScoreCategory = payload => {
    return {
        type: "GET_REVIEW_SCORE_CATEGORY",
        payload: payload
    }
}

export const getReviewScoreCategories = payload => {
    return {
        type: "GET_REVIEW_SCORE_CATEGORIES",
        payload: payload
    }
}

export const setName = payload => {
    return {
        type: "SET_REVIEW_SCORE_CATEGORY_NAME",
        payload: payload
    }
}

export const setIsActive = payload => {
    return {
        type: "SET_REVIEW_SCORE_CATEGORY_IS_ACTIVE",
        payload: payload
    }
}