export const getBookingFeedback = payload => {
    return {
        type: "GET_BOOKING_FEEDBACK",
        payload: payload
    }
}

export const getBookingFeedbacks = payload => {
    return {
        type: "GET_BOOKING_FEEDBACKS",
        payload: payload
    }
}