import PropTypes from 'prop-types';
import React from "react";
import { NavLink } from "react-router-dom";

import { Badge } from "antd";
import { Box, Button, ListItem } from '@mui/material';

export const NavItem = (props) => {
    const {to, icon, title, badge, ...others} = props;
    const [active, setActive] = React.useState(to ? (window.location.pathname === to) : false);

    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                mb: 0.5,
                py: 0,
                px: 2
            }}
            {...others}
        >
            <NavLink
                to={to}
                className={({isActive}) => {
                    setActive(isActive);
                }}
                style={{ width: '100%' }}
            >
                <Button
                    startIcon={icon}
                    disableRipple
                    sx={{
                        backgroundColor: active && 'rgba(255,255,255, 0.08)',
                        borderRadius: 1,
                        color: active ? 'secondary.main' : 'neutral.300',
                        fontWeight: active && 'fontWeightBold',
                        justifyContent: 'flex-start',
                        px: 3,
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                        '& .MuiButton-startIcon': {
                            color: active ? 'secondary.main' : 'neutral.400'
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255,255,255, 0.08)'
                        }
                    }}
                >
                    <Box sx={{flexGrow: 1}}>
                        {title}
                    </Box>
                    <Badge count={badge} />
                </Button>
            </NavLink>
        </ListItem>
    );
};

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.node,
    title: PropTypes.string
};
