import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";

import { ErrorBoundary } from "../../../Common/ErrorBoundary/errorBoundary";

export function Layout(props) {
    let url = useLocation();

    useEffect(() => {
        window.ym(90010721, 'hit', window.location.href, {
            title: document.title,
            referer: window.location.href,
        });
    }, [url])

    return (
        <ErrorBoundary>
            <Outlet />
        </ErrorBoundary>
    );
}