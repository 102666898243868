const initialState = {
    reviewScoreCategory: {
        is_active: false,
    },
    reviewScoreCategories: null,
}

export default function review(state = initialState, action) {
    switch (action.type) {
        case "GET_REVIEW_SCORE_CATEGORY":
            return {...state, reviewScoreCategory: action.payload};
        case "GET_REVIEW_SCORE_CATEGORIES":
            return {...state, reviewScoreCategories: action.payload};
        case "SET_REVIEW_SCORE_CATEGORY_NAME":
            return {...state, reviewScoreCategory: {...state.reviewScoreCategory, name: action.payload}};
        case "SET_REVIEW_SCORE_CATEGORY_IS_ACTIVE":
            return {...state, reviewScoreCategory: {...state.reviewScoreCategory, is_active: action.payload}};
        default: return state;
    }
};