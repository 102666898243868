const initialState = {
    answerTimeIndex: null,
    calendarUpdateTimeIndex: null
}

export default function ratingCoefficient(state = initialState, action) {
    switch(action.type){
        case "GET_ANSWER_TIME_INDEX":
            return {...state, answerTimeIndex: action.payload};
        case "GET_CALENDAR_UPDATE_TIME_INDEX":
            return {...state, calendarUpdateTimeIndex: action.payload};
        default: return state;
    }
};