export const getUsers = payload => {
    return {
        type: "GET_USERS",
        payload: payload
    }
}

export const getUser = payload => {
    return {
        type: "GET_USER",
        payload: payload
    }
}

export const setFirstName = payload => {
    return {
        type: "SET_FIRST_NAME",
        payload: payload
    }
}

export const setEmail = payload => {
    return {
        type: "SET_EMAIL",
        payload: payload
    }
}

export const setPhone = payload => {
    return {
        type: "SET_PHONE",
        payload: payload
    }
}

export const setTelegramSubscribeLink = payload => {
    return {
        type: "SET_TELEGRAM_SUBSCRIBE_LINK",
        payload: payload
    }
}

export const setStatusEmail = payload => {
    return {
        type: "SET_STATUS_EMAIL",
        payload: payload
    }
}

export const setStatusTelegram = payload => {
    return {
        type: "SET_STATUS_TELEGRAM",
        payload: payload
    }
}

export const setTelegram = payload => {
    return {
        type: "SET_TELEGRAM",
        payload: payload
    }
}

export const getChat = payload => {
    return {
        type: "GET_CHAT",
        payload: payload
    }
}

export const getChats = payload => {
    return {
        type: "GET_CHATS",
        payload: payload
    }
}

export const setId = payload => {
    return {
        type: "SET_ID",
        payload: payload
    }
}

export const setPermissions = payload => {
    return {
        type: "SET_PERMISSIONS",
        payload: payload
    }
}

export const getUserProfile = payload => {
    return {
        type: "GET_USER_PROFILE",
        payload: payload
    }
}

export const setUserProfileFirstName = payload => {
    return {
        type: "SET_USER_PROFILE_FIRST_NAME",
        payload: payload
    }
}

export const setUserProfileLastName = payload => {
    return {
        type: "SET_USER_PROFILE_LAST_NAME",
        payload: payload
    }
}

export const setUserProfileGender = payload => {
    return {
        type: "SET_USER_PROFILE_GENDER",
        payload: payload
    }
}

export const setUserProfileBirthdate = payload => {
    return {
        type: "SET_USER_PROFILE_BIRTHDATE",
        payload: payload
    }
}

export const setUserProfilePhoto = payload => {
    return {
        type: "SET_USER_PROFILE_PHOTO",
        payload: payload
    }
}

export const setUserProfileLocation = payload => {
    return {
        type: "SET_USER_PROFILE_LOCATION",
        payload: payload
    }
}

export const getUserPhones = payload => {
    return {
        type: "GET_USER_PHONES",
        payload: payload
    }
}