export const getPropertyFavourites = payload => {
    return {
        type: "GET_PROPERTY_FAVOURITES",
        payload: payload
    }
}

export const getPropertyFavourite = payload => {
    return {
        type: "GET_PROPERTY_FAVOURITE",
        payload: payload
    }
}