import { Outlet, useLocation } from "react-router-dom";
import { useEffect, Suspense } from "react";

import { ErrorBoundary } from "../../../Common/ErrorBoundary/errorBoundary";
import { SimpleLoader } from "../../../Common/Loader/simpleLoader";

export function Layout() {
    let url = useLocation();

    useEffect(() => {
        window.ym(90010721, 'hit', window.location.href, {
            title: document.title,
            referer: window.location.href,
        });
    }, [url])

    return (
        <ErrorBoundary>
            <Suspense fallback={<SimpleLoader />}>
                <Outlet />
            </Suspense>
        </ErrorBoundary>
    );
}