import React from "react"
import { useNavigate } from "react-router-dom";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import MailIcon from "@mui/icons-material/Mail";
import Paper from "@mui/material/Paper";

export function Menu() {
    const navigate = useNavigate();

    return(
        <Paper sx={{ position: 'fixed !important', bottom: 0, width: '100%', zIndex: 999 }} elevation={3}>
            <BottomNavigation showLabels value={window.location.pathname.split('/')[2]}>
                <BottomNavigationAction
                    sx={{ minWidth: '60px', padding: 0 }}
                    label={<div style={{ fontSize: '11px' }}>Поиск</div>}
                    value="search"
                    onClick={() => navigate(`/`)}
                    icon={<SearchIcon />}
                />
                <BottomNavigationAction
                    sx={{ minWidth: '60px', padding: 0 }}
                    label={<div style={{ fontSize: '11px' }}>Избранные</div>}
                    value="wishlists"
                    onClick={() => navigate(`/guest/wishlists`)}
                    icon={<FavoriteIcon />}
                />
                <BottomNavigationAction
                    sx={{ minWidth: '60px', padding: 0 }}
                    label={<div style={{ fontSize: '11px' }}>Поездки</div>}
                    value="reservations"
                    onClick={() => navigate(`/guest/reservations`)}
                    icon={<LocalTaxiIcon />}
                />
                <BottomNavigationAction
                    sx={{ minWidth: '60px', padding: 0 }}
                    label={<div style={{ fontSize: '11px' }}>Входящие</div>}
                    value="inbox"
                    onClick={() => navigate(`/guest/inbox`)}
                    icon={<MailIcon />}
                />
                <BottomNavigationAction
                    sx={{ minWidth: '60px', padding: 0 }}
                    label={<div style={{ fontSize: '11px' }}>Профиль</div>}
                    value="profile"
                    onClick={() => navigate(`/guest/profile`)}
                    icon={<AccountCircleIcon />}
                />
            </BottomNavigation>
        </Paper>
    )
}