const initialState = {
    guests: null,
    startDate: null,
    endDate: null,
}
//TODO
export default function urlRequest(state = initialState, action) {
    switch (action.type){
        case "SET_URL_REQUEST":
            return {...state,
                guests: action.payload.guests,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
            };
        default: return state;
    }
};
