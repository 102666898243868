import { Suspense, lazy } from 'react';
import { createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom';
import {
    Route,
    Navigate
} from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';

import { ErrorBoundary } from "../../../Common/ErrorBoundary/errorBoundary";
import Error404 from "../../../Common/Error/error404";
import { SimpleLoader } from "../../../Common/Loader/simpleLoader";

import { themeContent } from "../../../../Themes/themes";
import { Layout } from "./layout";
import { Layout as LayoutAccount } from "../../../Account/Account/Desktop/Layout/layout";
import { Layout as LayoutBasic } from "../Layout/layout";
import { Layout as LayoutGuest } from "../../../Account/secure/Desktop/Layout/layout";
import { Layout as LayoutHost } from "../../../Account/Extranet/Desktop/Layout/layout";
import { Layout as LayoutAdmin } from "../../../Admin/Layouts/layout";

const Basic = {
    Book: lazy(() => import("./Business/Book/book")),
    Confirmation: lazy(() => import("./Business/Book/Confirmation/confirmation")),
    EmailVerification: lazy(() => import("../../../Common/Email/emailVerification")),
    PasswordRecovery: lazy(() => import("../../../Account/Account/Desktop/Content/PasswordRecovery/passwordRecovery")),
    City: lazy(() => import("./City/city")),
    Main: lazy(() => import("./Main/main")),
    Business: lazy(() => import("./Business/business")),
}

const Pages = {
    Country: lazy(() => import("../../../Common/Pages/Country/country")),
    Region: lazy(() => import("../../../Common/Pages/Region/region")),
    District: lazy(() => import("../../../Common/Pages/District/disctrict")),
    Landmark: lazy(() => import("../../../Common/Pages/Landmark/landmark")),
    Home: lazy(() => import("../../../Common/Pages/Home/home")),
    Apartments: lazy(() => import("../../../Common/Pages/Apartments/apartments")),
    Hostels: lazy(() => import("../../../Common/Pages/Hostels/hostels")),
    Accommodations: lazy(() => import("../../../Common/Pages/Accommodations/accommodations")),
    TravelAgents: lazy(() => import("../../../Common/Pages/TravelAgents/travelAgents")),
    About: lazy(() => import("../../../Common/Pages/About/about")),
    Help: lazy(() => import("../../../Common/Pages/Help/help")),
    Partner: lazy(() => import("../../../Common/Pages/Partner/partner")),
    Sustainability: lazy(() => import("../../../Common/Pages/Sustainability/sustainability")),
    TrustAndSafity: lazy(() => import("../../../Common/Pages/TrustAndSafity/trustAndSafity")),
    Terms: lazy(() => import("../../../Common/Pages/Terms/terms")),
    Complaints: lazy(() => import("../../../Common/Pages/Complaints/complaints")),
    HowWeWork: lazy(() => import("../../../Common/Pages/HowWeWork/howWeWork")),
    Privacy: lazy(() => import("../../../Common/Pages/Privacy/privacy")),
    ContactUs: lazy(() => import("../../../Common/Pages/ContactUs/contactUs")),
    Articles: lazy(() => import("../../../Common/Pages/Articles/articles")),
    Affiliates: lazy(() => import("../../../Common/Pages/Affiliates/affiliates")),
    PrivacyPolicy: lazy(() => import("../../../Common/Pages/PrivacyPolicy/privacyPolicy")),
    PublicContract: lazy(() => import("../../../Common/Pages/PublicСontract/publicContract")),
    Payment: lazy(() => import("../../../Common/Pages/Payment/payment")),
    City: lazy(() => import("../../../Common/Pages/Country/country")),
}

const Host = {
    Today: lazy(() => import("../../../Account/Extranet/Desktop/Content/Today/today")),
    Calendar: lazy(() => import("../../../Account/Extranet/Desktop/Content/Calendar/calendar")),
    CalendarUpdate: lazy(() => import("../../../Account/Extranet/Desktop/Content/Calendar/Update/update")),
    Properties: lazy(() => import("../../../Account/Extranet/Desktop/Content/Properties/properties")),
    PropertyCreate: lazy(() => import("../../../Account/Extranet/Desktop/Content/Properties/Create/create")),
    PropertyView: lazy(() => import("../../../Account/Extranet/Desktop/Content/Properties/View/view")),
    PropertyUpdate: lazy(() => import("../../../Account/Extranet/Desktop/Content/Properties/Update/update")),
    RoomsAndSpaces: lazy(() => import("../../../Account/Extranet/Desktop/Content/Properties/Update/RoomsAndSpaces/roomsAndSpaces")),
    BookingRequest: lazy(() => import("../../../Account/Extranet/Desktop/Content/Bookings/bookingRequest")),
    Inbox: lazy(() => import("../../../Account/Extranet/Desktop/Content/Inbox/inbox")),
    Notifications: lazy(() => import("../../../Account/Extranet/Desktop/Content/AccountSettings/Notifications/notifications")),
    PersonalInfo: lazy(() => import("../../../Account/Extranet/Desktop/Content/AccountSettings/PersonalInfo/personalInfo")),
    AccountSettings: lazy(() => import("../../../Account/Extranet/Desktop/Content/AccountSettings/accountSettings")),
    Amenities: lazy(() => import("../../../Account/Extranet/Desktop/Content/Properties/Update/PageAmenities/pageAmenities")),
    Reviews: lazy(() => import("../../../Account/Extranet/Desktop/Content/Progress/Reviews/reviews")),
    Rules: lazy(() => import("../../../Account/Extranet/Desktop/Content/Properties/Update/PageRules/pageRules")),
}

const Admin = {
    UserPhones: lazy(() => import("../../../Admin/Content/UserPhones/userPhones")),
    Properties: lazy(() => import("../../../Admin/Content/Properties/properties")),
    Logs: lazy(() => import("../../../Admin/Content/Logs/logs")),
    LogView: lazy(() => import("../../../Admin/Content/Logs/View/view")),
    PropertyCreate: lazy(() => import("../../../Admin/Content/Properties/Create/create")),
    PropertyUpdate: lazy(() => import("../../../Admin/Content/Properties/Update/update")),
    PropertyView: lazy(() => import("../../../Admin/Content/Properties/View/view")),
    Users: lazy(() => import("../../../Admin/Content/Users/users")),
    UserUpdate: lazy(() => import("../../../Admin/Content/Users/Update/update")),
    UserView: lazy(() => import("../../../Admin/Content/Users/View/view")),
    Currencies: lazy(() => import("../../../Admin/Content/Currencies/currencies")),
    CurrencyCreate: lazy(() => import("../../../Admin/Content/Currencies/Create/create")),
    CurrencyUpdate: lazy(() => import("../../../Admin/Content/Currencies/Update/update")),
    PropertyTypes: lazy(() => import("../../../Admin/Content/PropertyTypes/propertyTypes")),
    PropertyTypeUpdate: lazy(() => import("../../../Admin/Content/PropertyTypes/Update/update")),
    PropertyTypeCreate: lazy(() => import("../../../Admin/Content/PropertyTypes/Create/create")),
    Regions: lazy(() => import("../../../Admin/Content/Regions/regions")),
    RegionCreate: lazy(() => import("../../../Admin/Content/Regions/Create/create")),
    RegionUpdate: lazy(() => import("../../../Admin/Content/Regions/Update/update")),
    Bookings: lazy(() => import("../../../Admin/Content/Bookings/bookings")),
    BookingView: lazy(() => import("../../../Admin/Content/Bookings/View/view")),
    Amenities: lazy(() => import("../../../Admin/Content/Amenities/amenities")),
    AmenityCreate: lazy(() => import("../../../Admin/Content/Amenities/Create/create")),
    AmenityUpdate: lazy(() => import("../../../Admin/Content/Amenities/Update/update")),
    AmenityView: lazy(() => import("../../../Admin/Content/Amenities/View/view")),
    AmenityCategories: lazy(() => import("../../../Admin/Content/AmenityCategories/amenityCategories")),
    AmenityCategoryCreate: lazy(() => import("../../../Admin/Content/AmenityCategories/Create/create")),
    AmenityCategoryView: lazy(() => import("../../../Admin/Content/AmenityCategories/View/view")),
    AmenityCategoryUpdate: lazy(() => import("../../../Admin/Content/AmenityCategories/Update/update")),
    RatingCoefficient: lazy(() => import("../../../Admin/Content/RatingCoefficient/ratingCoefficient")),
    Reviews: lazy(() => import("../../../Admin/Content/Reviews/reviews")),
    ReviewCreate: lazy(() => import("../../../Admin/Content/Reviews/Create/create")),
    ReviewUpdate: lazy(() => import("../../../Admin/Content/Reviews/Update/update")),
    ReviewView: lazy(() => import("../../../Admin/Content/Reviews/View/view")),
    ReviewScoreCategories: lazy(() => import("../../../Admin/Content/ReviewScoreCategories/reviewScoreCategories")),
    ReviewScoreCategoryUpdate: lazy(() => import("../../../Admin/Content/ReviewScoreCategories/Update/update")),
    ReviewScoreCategoryCreate: lazy(() => import("../../../Admin/Content/ReviewScoreCategories/Create/create")),
    BookingFeedbacks: lazy(() => import("../../../Admin/Content/BookingFeedbacks/bookingFeedbacks")),
    BookingFeedbackView: lazy(() => import("../../../Admin/Content/BookingFeedbacks/View/view")),
    Dashboard: lazy(() => import("../../../Admin/Content/Dashboard/dashboard")),
    Hosts: lazy(() => import("../../../Admin/Content/Hosts/hosts")),
    Settings: lazy(() => import("../../../Admin/Content/Settings/settings")),
    PropertyUpdateAmenities: lazy(() => import("../../../Admin/Content/Properties/Update/PageAmenities/pageAmenities")),
    PropertyUpdateRules: lazy(() => import("../../../Admin/Content/Properties/Update/PageRules/pageRules")),
}

const Guest = {
    AccountSettings: lazy(() => import("../../../Account/secure/Desktop/Content/AccountSettings/accountSettings")),
    Reservations: lazy(() => import("../../../Account/secure/Desktop/Content/Reservations/reservations")),
    WishLists: lazy(() => import("../../../Account/secure/Desktop/Content/WishList/wishLists")),
    Notifications: lazy(() => import("../../../Account/secure/Desktop/Content/AccountSettings/Notifications/notifications")),
    PersonalInfo: lazy(() => import("../../../Account/secure/Desktop/Content/AccountSettings/personalInfo/personalInfo")),
    Inbox: lazy(() => import("../../../Account/secure/Desktop/Content/Inbox/inbox")),
    Review: lazy(() => import("../../../Account/secure/Desktop/Content/Reservations/Review/review")),
    Reviews: lazy(() => import("../../../Account/secure/Desktop/Content/Progress/Reviews/reviews")),
}

const Auth = lazy(() => import("../../../Account/Account/Desktop/Content/Auth/auth"))

const theme = themeContent

export function Content(props) {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<Layout {...props} />}>
                <Route path="login" element={<LayoutAccount {...props} />}>
                    <Route path='*' element={<Error404 {...props} />}/>
                    <Route index element={<Auth {...props} />} />
                    <Route path="sign-up" element={<Auth {...props} />} />
                    <Route path="register" element={<Auth {...props} />} />
                </Route>
                <Route path="guest" element={<LayoutGuest {...props} />}>
                    <Route path='*' element={<Error404 {...props} />}/>
                    <Route index element={<Navigate replace to='reservations' />} />
                    <Route path='reservations' element={<Guest.Reservations {...props} />} />
                    <Route path='reservations/:id/review' element={<Guest.Review {...props} />} />
                    <Route path='wishlists' element={<Guest.WishLists {...props} />} />
                    <Route path='account-settings' element={<Guest.AccountSettings {...props} />} />
                    <Route path='account-settings/notifications' element={<Guest.Notifications {...props} />} />
                    <Route path='account-settings/personal-info' element={<Guest.PersonalInfo {...props} />} />
                    <Route path='inbox' element={<Guest.Inbox {...props} />} />
                    <Route path='inbox/:id' element={<Guest.Inbox {...props} />} />
                    <Route path='progress/reviews' element={<Guest.Reviews {...props} />} />
                    <Route path='progress/reviews/:id' element={<Guest.Reviews {...props} />} />
                </Route>
                <Route path="host" element={<LayoutHost {...props} />}>
                    <Route path='*' element={<Error404 {...props} />}/>
                    <Route index element={<Navigate replace to='properties' />} />
                    <Route path='today' element={<Host.Today {...props} />} />
                    <Route path='today/leaving' element={<Navigate replace to='/host/today' />} />
                    <Route path='today/living' element={<Navigate replace to='/host/today' />} />
                    <Route path='today/arriving' element={<Navigate replace to='/host/today' />} />
                    <Route path='today/upcoming' element={<Navigate replace to='/host/today' />} />
                    <Route path='calendar' element={<Host.Calendar {...props} />} />
                    <Route path='calendar/:id' element={<Host.CalendarUpdate {...props} />} />
                    <Route path='properties' element={<Host.Properties {...props} />} />
                    <Route path='properties/create' element={<Host.PropertyCreate {...props} />} />
                    <Route path='properties/:id' element={<Host.PropertyView {...props} />} />
                    <Route path='properties/:id/update' element={<Host.PropertyUpdate {...props} />} />
                    <Route path='properties/:id/update/rooms_and_spaces' element={<Host.RoomsAndSpaces {...props} />} />
                    <Route path='properties/:id/update/amenities' element={<Host.Amenities {...props} />} />
                    <Route path='properties/:id/update/rules' element={<Host.Rules {...props} />} />
                    <Route path='booking-request' element={<Host.BookingRequest {...props} />} />
                    <Route path='booking-request/upcoming' element={<Host.BookingRequest {...props} />}/>
                    <Route path='booking-request/completed' element={<Host.BookingRequest {...props} />} />
                    <Route path='booking-request/cancelled' element={<Host.BookingRequest {...props} />} />
                    <Route path='booking-request/all' element={<Host.BookingRequest {...props} />} />
                    <Route path='inbox' element={<Host.Inbox {...props} />} />
                    <Route path='inbox/:id' element={<Host.Inbox {...props} />} />
                    <Route path='account-settings' element={<Host.AccountSettings {...props} />} />
                    <Route path='account-settings/notifications' element={<Host.Notifications {...props} />} />
                    <Route path='account-settings/personal-info' element={<Host.PersonalInfo {...props} />} />
                    <Route path='progress/reviews' element={<Host.Reviews {...props} />} />
                    <Route path='progress/reviews/:id' element={<Host.Reviews {...props} />} />
                </Route>
                <Route element={<LayoutBasic {...props} />}>
                    <Route path='*' element={<Error404 {...props} />}/>
                    <Route index element={<Basic.Main {...props} />}/>
                    <Route path='/:city' element={<Basic.City { ...props } />} />
                    <Route path='/:city/search' element={<Basic.Main { ...props } />} />
                    <Route path='/:city/:id' element={<Basic.Business { ...props }/>} />
                    <Route path='/:city/:id/book/create' element={<Basic.Book { ...props }/>}/>
                    <Route path='/:city/:id/book/create/confirmation' element={<Basic.Confirmation { ...props }/>}/>
                    <Route path='/country' element={<Pages.Country />}/>
                    <Route path='/region' element={<Pages.Region />}/>
                    <Route path='/city' element={<Pages.City />}/>
                    <Route path='/district' element={<Pages.District />}/>
                    <Route path='/landmark' element={<Pages.Landmark />}/>
                    <Route path='/home' element={<Pages.Home />}/>
                    <Route path='/apartments' element={<Pages.Apartments />}/>
                    <Route path='/hostels' element={<Pages.Hostels />}/>
                    <Route path='/accommodations' element={<Pages.Accommodations />}/>
                    <Route path='/articles' element={<Pages.Articles />}/>
                    <Route path='/travel-agents' element={<Pages.TravelAgents />}/>
                    <Route path='/about' element={<Pages.About />}/>
                    <Route path='/help' element={<Pages.Help />}/>
                    <Route path='/partner' element={<Pages.Partner />}/>
                    <Route path='/sustainability' element={<Pages.Sustainability />}/>
                    <Route path='/trust-and-safity' element={<Pages.TrustAndSafity />}/>
                    <Route path='/terms' element={<Pages.Terms />}/>
                    <Route path='/complaints' element={<Pages.Complaints />}/>
                    <Route path='/how-we-work' element={<Pages.HowWeWork />}/>
                    <Route path='/privacy' element={<Pages.Privacy />}/>
                    <Route path='/contact-us' element={<Pages.ContactUs />}/>
                    <Route path='/affiliates' element={<Pages.Affiliates />}/>
                    <Route path='/privacy-policy' element={<Pages.PrivacyPolicy />}/>
                    <Route path='/user/email-verify' element={<Basic.EmailVerification />}/>
                    <Route path='/public-contract' element={<Pages.PublicContract />} />
                    <Route path='/payment' element={<Pages.Payment />} />
                    <Route path='/reset-password' element={<Basic.PasswordRecovery {...props} />} />
                </Route>
                <Route path="admin" element={<LayoutAdmin {...props} />}>
                    <Route path='*' element={<Error404 {...props} />}/>
                    <Route index element={<Navigate replace to='dashboard' />} />
                    <Route path='dashboard' element={<Admin.Dashboard {...props} />}/>
                    <Route path='user-phones' element={<Admin.UserPhones {...props} />}/>
                    <Route path='logs' element={<Admin.Logs {...props}/>}/>
                    <Route path='logs/:id/view' element={<Admin.LogView {...props}/>}/>
                    <Route path='properties' element={<Admin.Properties {...props} />}/>
                    <Route path='properties/create' element={<Admin.PropertyCreate {...props} />}/>
                    <Route path='properties/:id/update' element={<Admin.PropertyUpdate {...props} />}/>
                    <Route path='properties/:id/update/amenities' element={<Admin.PropertyUpdateAmenities {...props} />}/>
                    <Route path='properties/:id/update/rules' element={<Admin.PropertyUpdateRules {...props} />}/>
                    <Route path='properties/:id/view' element={<Admin.PropertyView {...props} />} />
                    <Route path='properties/:id' element={<Admin.Properties {...props} />}/>
                    <Route path='users' element={<Admin.Users {...props} />}/>
                    <Route path='users/create' element={<Admin.Users {...props} />}/>
                    <Route path='users/:id/update' element={<Admin.UserUpdate {...props} />}/>
                    <Route path='users/:id/view' element={<Admin.UserView {...props} />}/>
                    <Route path='currencies' element={<Admin.Currencies {...props} />}/>
                    <Route path='currencies/create' element={<Admin.CurrencyCreate {...props} />}/>
                    <Route path='currencies/:id/update' element={<Admin.CurrencyUpdate {...props} />}/>
                    <Route path='property-types' element={<Admin.PropertyTypes {...props} />}/>
                    <Route path='property-types/:id' element={<Admin.PropertyTypes {...props} />}/>
                    <Route path='property-types/:id/update' element={<Admin.PropertyTypeUpdate {...props} />}/>
                    <Route path='property-types/create' element={<Admin.PropertyTypeCreate {...props} />}/>
                    <Route path='regions' element={<Admin.Regions {...props} />}/>
                    <Route path='regions/create' element={<Admin.RegionCreate {...props} />}/>
                    <Route path='regions/:id/update' element={<Admin.RegionUpdate {...props} />}/>
                    <Route path='regions/:id' element={<Admin.Regions {...props} />}/>
                    <Route path='bookings' element={<Admin.Bookings {...props} />}/>
                    <Route path='bookings/:id/view' element={<Admin.BookingView {...props} />}/>
                    <Route path='amenities' element={<Admin.Amenities {...props} />}/>
                    <Route path='amenities/create' element={<Admin.AmenityCreate {...props} />}/>
                    <Route path='amenities/:id/update' element={<Admin.AmenityUpdate {...props} />}/>
                    <Route path='amenities/:id/view' element={<Admin.AmenityView {...props} />}/>
                    <Route path='amenity-categories' element={<Admin.AmenityCategories {...props} />}/>
                    <Route path='amenity-categories/create' element={<Admin.AmenityCategoryCreate {...props} />}/>
                    <Route path='amenity-categories/:id/view' element={<Admin.AmenityCategoryView {...props} />}/>
                    <Route path='amenity-categories/:id/update' element={<Admin.AmenityCategoryUpdate {...props} />}/>
                    <Route path='rating-coefficient' element={<Admin.RatingCoefficient {...props} />}/>
                    <Route path='reviews' element={<Admin.Reviews {...props} />}/>
                    <Route path='reviews/:id/view' element={<Admin.ReviewView {...props} />}/>
                    <Route path='reviews/create' element={<Admin.ReviewCreate {...props} />}/>
                    <Route path='reviews/:id/update' element={<Admin.ReviewUpdate {...props} />}/>
                    <Route path='review-score-categories' element={<Admin.ReviewScoreCategories {...props} />}/>
                    <Route path='review-score-categories/:id/update' element={<Admin.ReviewScoreCategoryUpdate {...props} />}/>
                    <Route path='review-score-categories/create' element={<Admin.ReviewScoreCategoryCreate {...props} />}/>
                    <Route path='booking-feedbacks' element={<Admin.BookingFeedbacks {...props} />}/>
                    <Route path='booking-feedbacks/:id/view' element={<Admin.BookingFeedbackView {...props} />}/>
                    <Route path='hosts' element={<Admin.Hosts {...props} />}/>
                    <Route path='settings' element={<Admin.Settings {...props} />}/>
                </Route>
            </Route>
        )
    );

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ErrorBoundary>
                    <Suspense fallback={<SimpleLoader />}>
                        <RouterProvider router={router} />
                    </Suspense>
                </ErrorBoundary>
            </ThemeProvider>
        </>
    )
}
