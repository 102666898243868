export const setIsEmptyPhone = payload => {
    return {
        type: "SET_IS_EMPTY_PHONE",
        payload: payload
    }
}

export const setIsRecoveryPassword = payload => {
    return {
        type: "SET_IS_RECOVERY_PASSWORD",
        payload: payload
    }
}

export const setIsResetPassword = payload => {
    return {
        type: "SET_IS_RESET_PASSWORD",
        payload: payload
    }
}

export const setIsMailMessageWasSent = payload => {
    return {
        type: "SET_IS_MAIL_MESSAGE_WAS_SENT",
        payload: payload
    }
}

export const setIsAccessToRedirect = payload => {
    return {
        type: "SET_IS_ACCESS_TO_REDIRECT",
        payload: payload
    }
}

export const setIsCityWithMap = payload => {
    return {
        type: "SET_IS_CITY_WITH_MAP",
        payload: payload
    }
}

export const setPropertyWasCreated = payload => {
    return {
        type: "SET_PROPERTY_WAS_CREATED",
        payload: payload
    }
}