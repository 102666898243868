const initialState = {
    permissions: null
}

export default function permission(state= initialState, action) {
    switch(action.type){
        case "GET_PERMISSIONS":
            return {...state, permissions: action.payload};
        default: return state;
    }
};