import { Suspense } from 'react';
import { Outlet } from "react-router-dom";

import { Menu } from "./Menu/menu";
import { Footer } from "./Footer/footer";
import { ErrorBoundary } from "../../../../Common/ErrorBoundary/errorBoundary";
import { SimpleLoader } from "../../../../Common/Loader/simpleLoader";

export function Layout(props) {
    return (
        <div>
            <Menu {...props} />
            <ErrorBoundary>
                <Suspense fallback={<SimpleLoader />}>
                    <Outlet />
                </Suspense>
            </ErrorBoundary>
            <Footer {...props} />
        </div>
    );
}