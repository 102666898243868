export const getDashboard1 = payload => {
    return {
        type: "GET_DASHBOARD_1",
        payload: payload
    }
}

export const getDashboard2 = payload => {
    return {
        type: "GET_DASHBOARD_2",
        payload: payload
    }
}