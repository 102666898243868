export const getProperties = payload => {
    return {
        type: "GET_PROPERTIES",
        payload: payload
    }
}

export const getProperty = payload => {
    return {
        type: "GET_PROPERTY",
        payload: payload
    }
}

export const setCalendarDate = payload => {
    return {
        type: "SET_CALENDAR_DATE",
        payload: payload
    }
}

export const setCoords = payload => {
    return {
        type: "SET_COORDS",
        payload: payload
    }
}

export const setPhotos = payload => {
    return {
        type: "SET_PHOTOS",
        payload: payload
    }
}

export const getInitialPhotos = payload => {
    return {
        type: "GET_INITIAL_PHOTOS",
        payload: payload
    }
}

export const deletePhotos = payload => {
    return {
        type: "DELETE_PHOTOS",
        payload: payload
    }
}

export const setCurrency = payload => {
    return {
        type: "SET_CURRENCY",
        payload: payload
    }
}

export const setPhone = payload => {
    return {
        type: "SET_PHONE",
        payload: payload
    }
}

export const setRegion = payload => {
    return {
        type: "SET_REGION",
        payload: payload
    }
}

export const setAdType = payload => {
    return {
        type: "SET_AD_TYPE",
        payload: payload
    }
}

export const setName = payload => {
    return {
        type: "SET_NAME",
        payload: payload
    }
}

export const setDescription = payload => {
    return {
        type: "SET_DESCRIPTION",
        payload: payload
    }
}

export const setCostPerPeriod = payload => {
    return {
        type: "SET_COST_PER_PERIOD",
        payload: payload
    }
}

export const setPrice = payload => {
    return {
        type: "SET_PRICE",
        payload: payload
    }
}

export const setGuests = payload => {
    return {
        type: "SET_GUESTS",
        payload: payload
    }
}

export const setInstantBookingAvailable = payload => {
    return {
        type: "SET_INSTANT_BOOKING_AVAILABLE",
        payload: payload
    }
}

export const setBedroomsInfo = payload => {
    return {
        type: "SET_BEDROOMS_INFO",
        payload: payload
    }
}

export const setSummaryNumberOfBedrooms = payload => {
    return {
        type: "SET_SUMMARY_BEDROOMS",
        payload: payload
    }
}

export const setSummaryNumberOfBeds = payload => {
    return {
        type: "SET_SUMMARY_BEDS",
        payload: payload
    }
}

export const setSummaryNumberOfBathrooms = payload => {
    return {
        type: "SET_SUMMARY_BATHROOMS",
        payload: payload
    }
}

export const getClosedBookingDates = payload => {
    return {
        type: "GET_CLOSED_BOOKING_DATES",
        payload: payload
    }
}

export const setAddress = payload => {
    return {
        type: "SET_ADDRESS",
        payload: payload
    }
}

export const setAddressSuplement = payload => {
    return {
        type: "SET_ADDRESS_SUPPLEMENT",
        payload: payload
    }
}

export const setStatus = payload => {
    return {
        type: "SET_STATUS",
        payload: payload
    }
}

export const setAdditionalGuests = payload => {
    return {
        type: "SET_ADDITIONAL_GUESTS",
        payload: payload
    }
}

export const setCostPerAdditionalGuest = payload => {
    return {
        type: "SET_COST_PER_ADDITIONAL_GUEST",
        payload: payload
    }
}

export const setAdditionalPrice = payload => {
    return {
        type: "SET_ADDITIONAL_PRICE",
        payload: payload
    }
}

export const setAmenities = payload => {
    return {
        type: "SET_AMENITIES",
        payload: payload
    }
}

export const getIcals = payload => {
    return {
        type: "GET_ICALS",
        payload: payload
    }
}

export const getIcal = payload => {
    return {
        type: "GET_ICAL",
        payload: payload
    }
}

export const getPropertiesForMap = payload => {
    return {
        type: "GET_PROPERTIES_FOR_MAP",
        payload: payload
    }
}

export const getRules = payload => {
    return {
        type: "GET_RULES",
        payload: payload
    }
}

export const setRulesCheckInTime = payload => {
    return {
        type: "SET_RULES_CHECK_IN_TIME",
        payload: payload
    }
}

export const setRulesCheckOutTime = payload => {
    return {
        type: "SET_RULES_CHECK_OUT_TIME",
        payload: payload
    }
}

export const setRulesMinStayDays = payload => {
    return {
        type: "SET_RULES_MIN_STAY_DAYS",
        payload: payload
    }
}

export const setRulesSuitableForChildren = payload => {
    return {
        type: "SET_RULES_SUITABLE_FOR_CHILDREN",
        payload: payload
    }
}

export const setRulesSuitableForInfants = payload => {
    return {
        type: "SET_RULES_SUITABLE_FOR_INFANTS",
        payload: payload
    }
}

export const setRulesPetsAllowed = payload => {
    return {
        type: "SET_RULES_PETS_ALLOWED",
        payload: payload
    }
}

export const setRulesSmokingAllowed = payload => {
    return {
        type: "SET_RULES_SMOKING_ALLOWED",
        payload: payload
    }
}

export const setRulesEventsAllowed = payload => {
    return {
        type: "SET_RULES_EVENTS_ALLOWED",
        payload: payload
    }
}

export const setRulesWithRentalAgreement = payload => {
    return {
        type: "SET_RULES_WITH_RENTAL_AGREEMENT",
        payload: payload
    }
}

export const setRulesDamageDepositAmount = payload => {
    return {
        type: "SET_RULES_DAMAGE_DEPOSIT_AMOUNT",
        payload: payload
    }
}

export const setRulesDamageDepositCurrency = payload => {
    return {
        type: "SET_RULES_DAMAGE_DEPOSIT_CURRENCY",
        payload: payload
    }
}

export const setRulesDownPaymentAmount = payload => {
    return {
        type: "SET_RULES_DOWN_PAYMENT_AMOUNT",
        payload: payload
    }
}

export const setRulesAdditionalRules = payload => {
    return {
        type: "SET_RULES_ADDITIONAL_RULES",
        payload: payload
    }
}