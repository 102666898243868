const initialState = {
    isEmptyPhone: false,
    isRecoveryPassword: false,
    isResetPassword: false,
    isMailMessageWasSent: false,
    isAccessToRedirect: false,
    isCityWithMap: false,
    propertyWasCreated: false,
}

export default function internalSystem(state= initialState, action) {
    switch(action.type){
        case "SET_IS_EMPTY_PHONE":
            return {...state, isEmptyPhone: action.payload};
        case "SET_IS_RECOVERY_PASSWORD":
            return {...state, isRecoveryPassword: action.payload};
        case "SET_IS_RESET_PASSWORD":
            return {...state, isResetPassword: action.payload};
        case "SET_IS_MAIL_MESSAGE_WAS_SENT":
            return {...state, isMailMessageWasSent: action.payload};
        case "SET_IS_ACCESS_TO_REDIRECT":
            return {...state, isAccessToRedirect: action.payload};
        case "SET_IS_CITY_WITH_MAP":
            return {...state, isCityWithMap: action.payload};
        case "SET_PROPERTY_WAS_CREATED":
            return {...state, propertyWasCreated: action.payload};
        default: return state;
    }
};