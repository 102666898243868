import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { updateUserProfile } from "../../../../../../Requests/CommonRequests/commonRequests";

export function Location(props) {
    const handleSubmit = () => {
        updateUserProfile(props)
            .then(res => {
                props.handleClose()
            })
    };

    return(
        <>
            <div style={{ display: 'flex' }}>
                <TextField
                    margin="normal"
                    defaultValue={props.user.user_profile.location}
                    required
                    label="Адрес"
                    name="location"
                    id="location"
                    onBlur={(event) => {props.userActions.setUserProfileLocation(event.target.value)}}
                    style = {{ width: 244, paddingRight: '20px' }}
                />
            </div>

            <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleSubmit()}
            >
                Сохранить
            </Button>
        </>
    )
}