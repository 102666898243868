import { AdvancedFetch, requestOptions } from "../../Utils/Helpers/Fetch/advancedFetch";
import lodash from "lodash";

export const getRoles = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/roles`, requestOptions)
        .then(
            (result) => {
                props.roleActions.getRoles(result);
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getPermissions = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/permissions`, requestOptions)
        .then(
            (result) => {
                props.permissionActions.getPermissions(result);
            },
            (error) => {
                console.log(error)
            }
        )
}

export const createPropertyType = (props) => {
    AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/property-types`, {
        ...requestOptions,
        body: JSON.stringify({
            name: props.propertyType.name,
        })
    })
        .then(
            (result) => {
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getCurrency = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/currencies/${id}`, requestOptions)
        .then(
            (result) => {
                props.currencyActions.getCurrency(result);
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getCurrencies = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/currencies`, requestOptions)
        .then(
            (result) => {
                props.currencyActions.getCurrencies(result);
            },
            (error) => {
                console.log(error)
            }
        )
}

export const updateCurrency = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/currencies/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify({
            iso_code: props.currency.currency.iso_code
        })
    })
}

export const createCurrency = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/currencies`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify({
            iso_code: props.currency.iso_code
        })
    })
}

export const getPropertyType = (props, id) => {
    return fetch(`${process.env.REACT_APP_REST_API}/admin/property-types/${id}`, requestOptions)
        .then(
            (result) => {
                props.propertyTypeActions.getPropertyType(result);
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getRegion = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/regions/${id}`, {
        ...requestOptions
    })
        .then(
            (result) => {
                console.log(result)
                props.regionActions.getRegion(result);
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getRegions = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/regions`, {
        ...requestOptions
    })
        .then(
            (result) => {
                props.regionActions.getRegions(result);
            },
            (error) => {
                console.log(error)
            }
        )
}

export const updateRegion = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/regions/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify({
            lat: '',
            lng: '',
            parent_id: '',
            name: '',
        })
    })
}

export const createRegion = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/regions`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify({
            lat: props.region.lat,
            lng: props.region.lng,
            parent_id: props.region.parentId,
            name: props.region.name,
        })
    })
}

export const updatePropertyType = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/property-types/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify({
            iso_code: props.propertyType.propertyType.name
        })
    })
}

export async function createProperty(props, userId) {
    const createPhotos = (props, content) => {
        return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/properties/${content.id}/photos`, {
            ...requestOptions,
            method: 'POST',
            body: JSON.stringify({
                images: props.property.photos,
            })
        })
    }

    const createContent = (props, userId) => {
        return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/properties`, {
            ...requestOptions,
            method: 'POST',
            body: JSON.stringify({
                user_id: userId,
                name: props.property.name,
                description: props.property.description,
                price: props.property.price,
                currency: props.property.currency,
                region_id: props.property.region.id,
                property_type_id: props.property.ad_type.id,
                lat: lodash.round(props.property.lat, 6),
                lng: lodash.round(props.property.lng, 6),
                guests: props.property.guests,
                instant_booking_available: props.property.instantBookingAvailable,
                rooms_and_spaces: props.property.roomsAndSpaces,
                address: props.property.address,
                address_supplement: props.property.address_supplement,
                additional_guests: props.property.additionalGuests,
                cost_per_additional_guest: props.property.costPerAdditionalGuest,
            })
        })
            .then(
                (result) => {
                    return result
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    let property = await createContent(props, userId);
    await Promise.all([
        createPhotos(props, property),
        updateAmenities(property.id, props.property.amenities),
        createPropertyRules(property.id, props.property.property.rules)
    ])
}

export const updateProperty = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/properties/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify({
            name: props.property.name,
            description: props.property.description,
            price: props.property.price,
            currency: props.property.currency,
            user_phone_id: props.property.phone.id,
            region_id: props.property.region.id,
            property_type_id: props.property.ad_type.id,
            lat: lodash.round(props.property.lat, 6),
            lng: lodash.round(props.property.lng, 6),
            guests: props.property.guests,
            instant_booking_available: props.property.instantBookingAvailable,
            rooms_and_spaces: props.property.roomsAndSpaces,
            address: props.property.address,
            address_supplement: props.property.address_supplement,
            status: props.property.status,
            additional_guests: props.property.additionalGuests,
            cost_per_additional_guest: props.property.costPerAdditionalGuest,
        })
    })
}

export const updatePropertyPhotos = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/properties/${id}/photos`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify({
            images: props.property.photos,
        })
    })
}

export const getBookings = (props, params) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/bookings?${params}`, requestOptions)
        .then(
            (result) => {
                props.bookingActions.getBookings(result);
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getBooking = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/bookings/${id}`, requestOptions)
        .then(
            (result) => {
                props.bookingActions.getBooking(result);
            },
            (error) => {
                console.log(error)
            }
        )
}

export const getLogs = (props, params) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/system-logs?${params}`, requestOptions)
        .then(
            (result) => {
                props.logActions.getLogs(result);
            },
            (error) => {
                console.log(error);
            }
        )
}

export const getLog = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/system-logs/${id}`, requestOptions)
        .then(
            (result) => {
                props.logActions.getLog(result);
            },
            (error) => {
                console.log(error);
            }
        )
}

export const deleteLog = (params) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/system-logs?${params}`, {
        ...requestOptions,
        method: 'DELETE'
    })
}

export const getAmenities = (props, params = '') => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/amenities${params}`, requestOptions)
        .then(
            (result) => {
                props.amenityActions.getAmenities(result);
            },
            (error) => {
                console.log(error);
            }
        )
}

export const getAmenityCategories = (props, params = '') => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/amenity-categories${params}`, requestOptions)
        .then(
            (result) => {
                props.amenityCategoryActions.getAmenityCategories(result);
            },
            (error) => {
                console.log(error);
            }
        )
}

export const createAmenityCategory = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/amenity-categories`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify({
            name: props.amenityCategory.amenityCategory.name,
            is_active: props.amenityCategory.amenityCategory.is_active,
            icon_path: props.amenityCategory.amenityCategory.icon_path,
        })
    })
}

export const getAmenityCategory = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/amenity-categories/${id}`, requestOptions)
        .then(
            (result) => {
                props.amenityCategoryActions.getAmenityCategory(result);
            },
            (error) => {
                console.log(error);
            }
        )
}

export const updateAmenityCategory = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/amenity-categories/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify({
            name: props.amenityCategory.amenityCategory.name,
            is_active: props.amenityCategory.amenityCategory.is_active,
            icon_path: props.amenityCategory.amenityCategory.icon_path,
            order: props.amenityCategory.amenityCategory.order,
        })
    })
}

export const createAmenity = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/amenities`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify({
            name: props.amenity.amenity.name,
            is_active: props.amenity.amenity.is_active,
            icon_path: props.amenity.amenity.icon_path,
            is_popular: props.amenity.amenity.is_popular,
            category_id: props.amenity.amenity.category_id,
        })
    })
}

export const getAmenity = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/amenities/${id}`, requestOptions)
        .then(
            (result) => {
                props.amenityActions.getAmenity(result);
            },
            (error) => {
                console.log(error);
            }
        )
}

export const updateAmenity = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/amenities/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify({
            name: props.amenity.amenity.name,
            category_id: props.amenity.amenity.category_id,
            is_active: props.amenity.amenity.is_active,
            is_popular: props.amenity.amenity.is_popular,
            icon_path: props.amenity.amenity.icon_path,
        })
    })
}

export const updateAmenities = (id, amenities) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/properties/${id}/amenities`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify(amenities)
    })
}

export const deleteAmenityCategory = (id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/amenity-categories/${id}`, {
        ...requestOptions,
        method: 'DELETE',
    })
}

export const deleteAmenity = (id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/amenities/${id}`, {
        ...requestOptions,
        method: 'DELETE',
    })
}

export const getProperties = (props, params) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/properties?${params}`, requestOptions)
}

export const getUsers = (props, params) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/users?${params}`, requestOptions)
}

export const addUserPhone = (body) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/user-phones`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export const getUserPhones = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/user-phones`, requestOptions)
        .then(
            (result) => {
                props.userActions.getUserPhones(result);
            },
            (error) => {
                console.log(error);
            }
        )
}

export const deleteUserPhone = (id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/user-phones/${id}`, {
        ...requestOptions,
        method: 'DELETE',
    })
}

export const getAnswerTimeIndex = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/answer-time-index`, requestOptions)
        .then(res => props.ratingCoefficientActions.getAnswerTimeIndex(res))
}

export const getCalendarUpdateTimeIndex = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/calendar-update-time-index`, requestOptions)
        .then(res => props.ratingCoefficientActions.getCalendarUpdateTimeIndex(res))
}

export const updateAnswerTimeIndex = (props, id, body) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/answer-time-index/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify(body)
    })
}


export const updateCalendarUpdateTimeIndex = (props, id, body) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/calendar-update-time-index/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify(body)
    })
}

export const getReviews = (props, params) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/booking-reviews?${params}`, requestOptions)
        .then(
            res => {
                props.reviewActions.getReviews(res)
                return res
            },
            error => console.log(error)
        )
}

export const createReview = (props) => {
    let body = props.review.review

    Object.keys(body).forEach(k => body[k] === '' && delete body[k])

    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/booking-reviews`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export const createExpandReviewScores = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/booking-reviews/${id}/expanded-review-scores`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify(props.review.expandedReviewScores)
    })
}

export const getReview = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/booking-reviews/${id}`, requestOptions)
        .then(res => {
            props.reviewActions.getReview(res)
            return res
        })
}

export const getExpandedReviewScores = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/booking-reviews/${id}/expanded-review-scores`, requestOptions)
        .then(
            (result) => {
                props.reviewActions.setExpandedReviewScores(result);
                return result;
            },
            (error) => {
                console.log(error);
            }
        )
}

export const updateReview = (props, id) => {
    let review = props.review.review;
    Object.keys({...review}).forEach(k => review[k] === ('' || null) && delete review[k])

    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/booking-reviews/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify(review)
    })
}

export const getReviewScoreCategories = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/review-score-categories`, requestOptions)
        .then(res => props.reviewScoreCategoryActions.getReviewScoreCategories(res))
}

export const createReviewScoreCategory = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/review-score-categories`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify(props.reviewScoreCategory.reviewScoreCategory)
    })
}

export const getReviewScoreCategory = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/review-score-categories/${id}`, requestOptions)
        .then(res => props.reviewScoreCategoryActions.getReviewScoreCategory(res))
}

export const updateReviewScoreCategory = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/review-score-categories/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify(props.reviewScoreCategory.reviewScoreCategory)
    })
}

export const getBookingFeedbacks = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/booking-feedbacks`, requestOptions)
        .then(res => props.bookingFeedbackActions.getBookingFeedbacks(res))
}

export const getBookingFeedback = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/booking-feedbacks/${id}`, requestOptions)
        .then(res => props.bookingFeedbackActions.getBookingFeedback(res))
}

export const updateBooking = (props, id, body) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/bookings/${id}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify(body)
    })
}

export const getDashboard1 = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/widget/dashboard-1`, requestOptions)
        .then(res => props.widgetActions.getDashboard1(res))
}

export const getDashboard2 = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/widget/dashboard-2`, requestOptions)
        .then(res => props.widgetActions.getDashboard2(res))
}

export const getKeyStorageItems = (props) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/key-storage-items`, requestOptions)
        .then(res => props.keyStorageItemActions.getKeyStorageItems(res))
}

export const updateKeyStorageItem = (props, key, body) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/key-storage-items/${key}`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify(body)
    })
}

export const getProperty = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/properties/${id}`, requestOptions)
        .then(
            (result) => {
                props.propertyActions.getProperty(result);

                let photos = [];
                let initialPhotos = [];

                result.photos.forEach((el) => {
                    photos.push({
                        'path': el.path
                    });

                    initialPhotos.push({
                        'url': el.url
                    });
                })

                props.propertyActions.setPhotos(photos);
                props.propertyActions.getInitialPhotos(initialPhotos);

                return result
            },
            (error) => {
                console.log(error);
                throw new Error('404 Page Not Found');
            }
        )
}

export const getPropertyRules = (props, id) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/properties/${id}/property-rules`, requestOptions)
        .then(
            (result) => {
                props.propertyActions.getRules(result);
            },
            (error) => console.log(error)
        )
}

export const updatePropertyRules = (id, rules) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/admin/properties/${id}/property-rules`, {
        ...requestOptions,
        method: 'PUT',
        body: JSON.stringify(lodash(rules).omitBy(lodash.isNil).value())
    })
}

export const createPropertyRules = (id, rules) => {
    return AdvancedFetch(`${process.env.REACT_APP_REST_API}/extranet/properties/${id}/property-rules`, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify(lodash(rules).omitBy(lodash.isNil).value())
    })
}