const initialState = {
    booking: null,
    bookings: null,
}

export default function booking(state= initialState, action) {
    switch(action.type){
        case "GET_BOOKING":
            return {...state, booking: action.payload};
        case "GET_BOOKINGS":
            return {...state, bookings: action.payload};
        case "GET_ADDED_BOOKING":
            return {...state, bookings: [...state.bookings, ...action.payload]};
        default: return state;
    }
};