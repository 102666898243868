export const getBookings = payload => {
    return {
        type: "GET_BOOKINGS",
        payload: payload
    }
}

export const getBooking = payload => {
    return {
        type: "GET_BOOKING",
        payload: payload
    }
}

export const getAddedBooking = payload => {
    return {
        type: "GET_ADDED_BOOKING",
        payload: payload
    }
}