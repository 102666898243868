import ruLocale from 'date-fns/locale/ru';
import { addYears } from "date-fns";

import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import Button from "@mui/material/Button";

import { dateForRequestFormat } from "../../../../../../Utils/Helpers/Date/date";
import { updateUserProfile } from "../../../../../../Requests/CommonRequests/commonRequests";

export function BirthDate(props) {
    const handleSubmit = () => {
        updateUserProfile(props)
            .then(res => {
                props.handleClose()
            })
    };

    return(
        <>
            <div style={{ paddingTop: '12px' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                    <DesktopDatePicker
                        mask="__.__.____"
                        value={props.user.user_profile.birthdate}
                        minDate={addYears(new Date(), -100)}
                        maxDate={new Date()}
                        onChange={(newValue) => {
                            props.userActions.setUserProfileBirthdate(dateForRequestFormat(newValue));
                        }}
                        label="Дата рождения"
                        renderInput={(params) => <TextField {...params}
                                                            sx={{ background: "white", borderRadius: "3px" }}
                        />}
                    />
                </LocalizationProvider>
            </div>

            <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleSubmit()}
            >
                Сохранить
            </Button>
        </>
    )
}