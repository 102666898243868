// Core
import { lazy } from 'react';
import { Navigate, Route } from "react-router-dom";
import { createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom';

// UI
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Tools
import { themeContent } from "../../../../Themes/themes";

import { Layout as LayoutAccount } from "../../../Account/Account/Mobile/Layout/layout";
import { Layout as LayoutGuest } from "../../../Account/secure/Mobile/Layout/layout";
import { Layout as LayoutHost } from "../../../Account/Extranet/Mobile/Layout/layout";
import { Layout as LayoutBasic } from "../Layout/layout";
import { Layout } from "./layout";
import { ErrorBoundary } from "../../../Common/ErrorBoundary/errorBoundary";
import Error404 from "../../../Common/Error/error404";

import { PersonalInfo as PersonalInfoHost } from "../../../Account/Extranet/Mobile/Content/AccountSettings/PersonalInfo/personalInfo";

const Basic = {
    Book: lazy(() => import("./Business/Book/book")),
    Confirmation: lazy(() => import("./Business/Book/Confirmation/confirmation")),
    EmailVerification: lazy(() => import("../../../Common/Email/emailVerification")),
    PasswordRecovery: lazy(() => import("../../../Account/Account/Desktop/Content/PasswordRecovery/passwordRecovery")),
    City: lazy(() => import("./City/city")),
    CitySearch: lazy(() => import("./CitySearch/citySearch")),
    Main: lazy(() => import("./Main/main")),
    Business: lazy(() => import("./Business/business")),
}

const Pages = {
    Terms: lazy(() => import("../../../Common/Pages/Terms/terms")),
    ContactUs: lazy(() => import("../../../Common/Pages/ContactUs/contactUs")),
    HowWeWork: lazy(() => import("../../../Common/Pages/HowWeWork/howWeWork")),
    PublicContract: lazy(() => import("../../../Common/Pages/PublicСontract/publicContract")),
    Payment: lazy(() => import("../../../Common/Pages/Payment/payment")),
}

const Guest = {
    Reservations: lazy(() => import("../../../Account/secure/Mobile/Content/Reservations/reservations")),
    Profile: lazy(() => import("../../../Account/secure/Mobile/Content/Profile/profile")),
    Inbox: lazy(() => import("../../../Account/secure/Mobile/Content/Inbox/inbox")),
    WishLists: lazy(() => import("../../../Account/secure/Mobile/Content/WishList/wishLists")),
    AccountSettings: lazy(() => import("../../../Account/secure/Mobile/Content/AccountSettings/accountSettings")),
    Notifications: lazy(() => import("../../../Account/secure/Mobile/Content/AccountSettings/Notifications/notifications")),
    PersonalInfo: lazy(() => import("../../../Account/secure/Mobile/Content/AccountSettings/PersonalInfo/personalInfo")),
    Chat: lazy(() => import("../../../Account/secure/Mobile/Content/Inbox/Chat/chat")),
    Review: lazy(() => import("../../../Account/secure/Mobile/Content/Reservations/Review/review")),
    Reviews: lazy(() => import("../../../Account/secure/Mobile/Content/Progress/Reviews/reviews")),
    ReviewView: lazy(() => import("../../../Account/secure/Mobile/Content/Progress/Reviews/View/view")),
}

const Host = {
    Properties: lazy(() => import("../../../Account/Extranet/Mobile/Content/Properties/properties")),
    PropertyCreate: lazy(() => import("../../../Account/Extranet/Mobile/Content/Properties/Create/create")),
    PropertyView: lazy(() => import("../../../Account/Extranet/Mobile/Content/Properties/View/view")),
    PropertyUpdate: lazy(() => import("../../../Account/Extranet/Mobile/Content/Properties/Update/update")),
    BookingRequest: lazy(() => import("../../../Account/Extranet/Mobile/Content/Bookings/bookingRequest")),
    Calendar: lazy(() => import("../../../Account/Extranet/Mobile/Content/Calendar/calendar")),
    CalendarUpdate: lazy(() => import("../../../Account/Extranet/Mobile/Content/Calendar/Update/update")),
    Today: lazy(() => import("../../../Account/Extranet/Mobile/Content/Today/today")),
    Profile: lazy(() => import("../../../Account/Extranet/Mobile/Content/Profile/profile")),
    Inbox: lazy(() => import("../../../Account/Extranet/Mobile/Content/Inbox/inbox")),
    Chat: lazy(() => import("../../../Account/Extranet/Mobile/Content/Inbox/Chat/chat")),
    AccountSettings: lazy(() => import("../../../Account/Extranet/Mobile/Content/AccountSettings/accountSettings")),
    Notifications: lazy(() => import("../../../Account/Extranet/Mobile/Content/AccountSettings/Notifications/notifications")),
    //PersonalInfoHost: lazy(() => import("../../../Account/Extranet/Mobile/Content/AccountSettings/PersonalInfo/personalInfo")),
    Amenities: lazy(() => import("../../../Account/Extranet/Mobile/Content/Properties/Update/PageAmenities/pageAmenities")),
    Rules: lazy(() => import("../../../Account/Extranet/Mobile/Content/Properties/Update/PageRules/pageRules")),
    Reviews: lazy(() => import("../../../Account/Extranet/Mobile/Content/Progress/Reviews/reviews")),
    ReviewView: lazy(() => import("../../../Account/Extranet/Mobile/Content/Progress/Reviews/View/view")),
}

const Auth = lazy(() => import("../../../Account/Account/Desktop/Content/Auth/auth"))

const theme = themeContent;

export function MobileContent(props) {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<Layout {...props} />}>
                <Route path="login" element={<LayoutAccount {...props} />}>
                    <Route path='*' element={<Error404 {...props} />}/>
                    <Route index element={<Auth {...props} />} />
                    <Route path="sign-up" element={<Auth {...props} />} />
                    <Route path="register" element={<Auth {...props} />} />
                </Route>
                <Route path='guest' element={<LayoutGuest {...props} />} >
                    <Route path='*' element={<Error404 {...props} />}/>
                    <Route index element={<Navigate replace to='/reservations' />} />
                    <Route path='reservations' element={<Guest.Reservations {...props} />} />
                    <Route path='reservations/:id/review' element={<Guest.Review {...props} />} />
                    <Route path='wishlists' element={<Guest.WishLists {...props} />} />
                    <Route path='profile' element={<Guest.Profile {...props} />} />
                    <Route path='account-settings' element={<Guest.AccountSettings {...props} />} />
                    <Route path='account-settings/notifications' element={<Guest.Notifications {...props} />} />
                    <Route path='account-settings/personal-info' element={<Guest.PersonalInfo {...props} />} />
                    <Route path='inbox' element={<Guest.Inbox{...props} />} />
                    <Route path='inbox/:id' element={<Guest.Chat {...props} />} />
                    <Route path='progress/reviews' element={<Guest.Reviews {...props} />} />
                    <Route path='progress/reviews/:id' element={<Guest.ReviewView {...props} />} />
                </Route>
                <Route path='host' element={<LayoutHost {...props} />}>
                    <Route path='*' element={<Error404 {...props} />}/>
                    <Route index element={<Navigate replace to='properties' />} />
                    <Route path='properties' element={<Host.Properties {...props} />} />
                    <Route path='properties/create' element={<Host.PropertyCreate {...props} />} />
                    <Route path='properties/:id' element={<Host.PropertyView {...props} />} />
                    <Route path='properties/:id/update' element={<Host.PropertyUpdate {...props} />} />
                    <Route path='properties/:id/update/amenities' element={<Host.Amenities {...props} />} />
                    <Route path='properties/:id/update/rules' element={<Host.Rules {...props} />} />
                    <Route path='booking-request' element={<Host.BookingRequest {...props} />} />
                    <Route path='booking-request/upcoming' element={<Host.BookingRequest {...props} />}/>
                    <Route path='booking-request/completed' element={<Host.BookingRequest {...props} />} />
                    <Route path='booking-request/cancelled' element={<Host.BookingRequest {...props} />} />
                    <Route path='booking-request/all' element={<Host.BookingRequest {...props} />} />
                    <Route path='calendar' element={<Host.Calendar {...props} />} />
                    <Route path='calendar/:id' element={<Host.CalendarUpdate {...props} />} />
                    <Route path='today' element={<Host.Today {...props} />} />
                    <Route path='today/leaving' element={<Navigate replace to='/host/today' />} />
                    <Route path='today/living' element={<Navigate replace to='/host/today' />} />
                    <Route path='today/arriving' element={<Navigate replace to='/host/today' />} />
                    <Route path='today/upcoming' element={<Navigate replace to='/host/today' />} />
                    <Route path='inbox' element={<Host.Inbox {...props} />} />
                    <Route path='inbox/:id' element={<Host.Chat {...props} />} />
                    <Route path='profile' element={<Host.Profile {...props} />} />
                    <Route path='account-settings' element={<Host.AccountSettings {...props} />} />
                    <Route path='account-settings/notifications' element={<Host.Notifications {...props} />} />
                    <Route path='account-settings/personal-info' element={<PersonalInfoHost {...props} />} />
                    <Route path='progress/reviews' element={<Host.Reviews {...props} />} />
                    <Route path='progress/reviews/:id' element={<Host.ReviewView {...props} />} />
                </Route>
                <Route element={<LayoutBasic {...props} />}>
                    <Route path='*' element={<Error404 {...props} />}/>
                    <Route index element={<Basic.Main {...props} />}/>
                    <Route path='/:city' element={<Basic.City { ...props } />}/>
                    <Route path='/:city/search' element={<Basic.CitySearch { ...props } />}/>
                    <Route path='/contact-us' element={<Pages.ContactUs />}/>
                    <Route path='/terms' element={<Pages.Terms />}/>
                    <Route path='/how-we-work' element={<Pages.HowWeWork />}/>
                    <Route path='/public-contract' element={<Pages.PublicContract />}/>
                    <Route path='/payment' element={<Pages.Payment />}/>
                </Route>

                <Route path='/:city/:id' element={<Basic.Business { ...props } />}/>
                <Route path='/:city/:id/book/create' element={<Basic.Book { ...props }/>}/>
                <Route path='/:city/:id/book/create/confirmation' element={<Basic.Confirmation { ...props }/>}/>
                <Route path='/user/email-verify' element={<Basic.EmailVerification />}/>
                <Route path='/reset-password' element={<Basic.PasswordRecovery {...props} />} />
            </Route>
        )
    );

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ErrorBoundary>
                    <RouterProvider router={router} />
                </ErrorBoundary>
            </ThemeProvider>
        </>
    )
}


