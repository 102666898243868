const initialState = {
    amenityCategory: null,
    amenityCategories: null,
}

export default function amenity(state= initialState, action) {
    switch(action.type){
        case "GET_AMENITY_CATEGORY":
            return {...state, amenityCategory: action.payload};
        case "GET_AMENITY_CATEGORIES":
            return {...state, amenityCategories: action.payload};
        case "SET_NAME":
            return {...state, amenityCategory: {...state.amenityCategory, name: action.payload}};
        case "SET_IS_ACTIVE":
            return {...state, amenityCategory: {...state.amenityCategory, is_active: action.payload}};
        case "SET_ICON_PATH":
            return {...state, amenityCategory: {...state.amenityCategory, icon_path: action.payload}};
        case "SET_ORDER":
            return {...state, amenityCategory: {...state.amenityCategory, order: action.payload}};
        default: return state;
    }
};