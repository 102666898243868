import * as React from 'react';
import {Link, useParams} from "react-router-dom";

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import s from "../../../../../../../Themes/reactComponents.module.scss"

export function NavigationTabs() {
    const [value, setValue] = React.useState(window.location.pathname.split('/')[2]);

    //refactor

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', bgColor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Сегодня" value="today" component={Link} className={s.Link} to={"today"}/>
                <Tab label="Входящие" value="inbox" component={Link} className={s.Link} to={"inbox"}/>
                <Tab label="Календарь" value="calendar" component={Link} className={s.Link} to={"calendar"}/>
                <Tab label="Объявления" value="properties" component={Link} className={s.Link} to={"properties"}/>
                <Tab label="Бронирования" value="booking-request" component={Link} className={s.Link} to={"booking-request/upcoming"}/>
            </Tabs>
        </Box>
    );
}