const initialState = {
    reviews: null,
    review: {
        is_anonymous: false
    },
    expandedReviewScores: []
}

export default function review(state = initialState, action) {
    switch (action.type) {
        case "GET_REVIEWS":
            return {...state, reviews: action.payload};
        case "GET_REVIEW":
            return {...state, review: action.payload};
        case "SET_SCORE":
            return {...state, review: {...state.review, score: action.payload}};
        case "SET_BOOKING_ID":
            return {...state, review: {...state.review, booking_id: action.payload}};
        case "SET_PUBLIC_COMMENT":
            return {...state, review: {...state.review, public_comment: action.payload}};
        case "SET_PRIVATE_COMMENT":
            return {...state, review: {...state.review, private_comment: action.payload}};
        case "SET_IS_ANONYMOUS":
            return {...state, review: {...state.review, is_anonymous: action.payload}};
        case "SET_IS_READ_MANAGER":
            return {...state, review: {...state.review, is_read_manager: action.payload}};
        case "SET_IS_READ_HOST":
            return {...state, review: {...state.review, is_read_host: action.payload}};
        case "SET_PROPERTY_ID":
            return {...state, review: {...state.review, property_id: action.payload}};
        case "SET_EXPANDED_REVIEW_SCORES":
            return {...state, expandedReviewScores: action.payload};
        case "SET_STATUS":
            return {...state, review: {...state.review, status: action.payload}};
        default: return state;
    }
};