import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { getUser, updateUserEmail } from "../../../../../../Requests/CommonRequests/commonRequests";

export function Email(props) {
    const handleSubmit = () => {
        let body = {
            email: document.getElementById('email').value
        };

        updateUserEmail(props, body)
            .then(() => getUser(props))
            .then(res => {
                props.handleClose()
            })
    };

    return(
        <>
            <div style={{ display: 'flex' }}>
                <TextField
                    margin="normal"
                    defaultValue={props.user.email}
                    required
                    label="Электронная почта"
                    name="email"
                    id="email"
                    style = {{ width: 244, paddingRight: '20px' }}
                />
            </div>

            <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleSubmit()}
            >
                Сохранить
            </Button>
        </>
    )
}