import { Outlet } from "react-router-dom";
import { Suspense, useEffect } from "react";
import Cookies from "js-cookie";

import { Menu } from "./Menu/menu";
import { Footer } from "./Footer/footer";
import { ErrorBoundary } from "../../../Common/ErrorBoundary/errorBoundary";
import { SimpleLoader } from "../../../Common/Loader/simpleLoader";
import { getUser } from "../../../../Requests/CommonRequests/commonRequests";

export function Layout(props) {
    useEffect(async () => {
        if (Cookies.get('token')) {
            const data = await getUser(props)

            if (data.payload.message?.startsWith('Unauth')) {
                (document.cookie = `token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=.${window.location.protocol === 'https:' ? process.env.REACT_APP_DOMAIN : process.env.REACT_APP_LOCAL_DOMAIN};path=/`);
                window.location.reload()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="container" style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
             <header style={{ flex: '0 0 auto' }}>
                 <Menu {...props} />
             </header>
             <main style={{ flex: '1 0 auto' }}>
                 <ErrorBoundary>
                     <Suspense fallback={<SimpleLoader />}>
                        <Outlet />
                     </Suspense>
                 </ErrorBoundary>
             </main>
             <footer style={{ flex: '0 0 auto' }}>
                 <Footer {...props} />
             </footer>
        </div>
    );
}