import React, { useEffect, useState, Suspense } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { Box, CssBaseline } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { getUser } from "../../../Requests/CommonRequests/commonRequests";
import { theme } from '../Theme/theme';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { ErrorBoundary } from "../../Common/ErrorBoundary/errorBoundary";
import { SimpleLoader } from "../../Common/Loader/simpleLoader";
import { getReviews } from "../../../Requests/AdminRequests/adminRequests";

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 280
    }
}));

export function Layout(props) {
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [reminderList, setReminderList] = useState({
        reviews: 0
    })
    const navigate = useNavigate();

    useEffect(() => {
        if (Cookies.get('token')) {
            Promise.all([getUser(props), getReviews(props, 'filter[is_read_manager]=false')])
                .then((res) =>
                    {
                        if (res[0].payload?.message === 'Unauthenticated.') {
                            navigate('/login')
                        } else {
                            setReminderList({ ...reminderList, reviews: res[1].meta.total })
                            setIsLoaded(true)
                        }
                    },
                    (error) => console.log(error)
                )
        } else {
            navigate('/login')
        }

        if (document.getElementsByClassName('b24-widget-button-wrapper b24-widget-button-position-bottom-right b24-widget-button-visible')[0]) {
            document.getElementsByClassName('b24-widget-button-wrapper b24-widget-button-position-bottom-right b24-widget-button-visible')[0].style.display = 'none'
        }

        return function cleanup() {
            if (document.getElementsByClassName('b24-widget-button-wrapper b24-widget-button-position-bottom-right b24-widget-button-visible')[0]) {
                document.getElementsByClassName('b24-widget-button-wrapper b24-widget-button-position-bottom-right b24-widget-button-visible')[0].style.display = 'flex'
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isLoaded ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <DashboardLayoutRoot>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flex: '1 1 auto',
                                    flexDirection: 'column',
                                    width: '100%'
                                }}
                            >
                                <ErrorBoundary>
                                    <Suspense fallback={<div></div>}>
                                        <Outlet />
                                    </Suspense>
                                </ErrorBoundary>
                            </Box>
                        </DashboardLayoutRoot>
                        <DashboardNavbar user_profile={props.user.user_profile} onSidebarOpen={() => setSidebarOpen(true)} />
                        <DashboardSidebar
                            {...props}
                            reminderList={reminderList}
                            onClose={() => setSidebarOpen(false)}
                            open={isSidebarOpen}
                        />
                    </ThemeProvider>
                </LocalizationProvider>
            ) : ''}
        </>
    );
}