import React from "react"
import { useNavigate } from "react-router-dom";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChatIcon from '@mui/icons-material/Chat';
import Paper from "@mui/material/Paper";
import DehazeOutlinedIcon from '@mui/icons-material/DehazeOutlined';
import TodayIcon from '@mui/icons-material/Today';

export function Menu(props) {
    const navigate = useNavigate();

    return(
        <Paper
            sx={{
                zIndex: 5,
                position: 'fixed !important',
                bottom: 0,
                left: 0,
                right: 0,
            }}
            elevation={3}
        >
            <BottomNavigation
                showLabels
                sx={{ fontSize: '11px' }}
                value={window.location.pathname.split('/')[2]}
            >
                <BottomNavigationAction
                    sx={{ minWidth: '60px', padding: 0 }}
                    label={<div style={{ fontSize: '11px' }}>Сегодня</div>}
                    value="today"
                    onClick={() => navigate(`/host/today`)}
                    icon={<TodayIcon />}
                />
                <BottomNavigationAction
                    sx={{ minWidth: '60px', padding: 0 }}
                    label={<div style={{ fontSize: '11px' }}>Входящие</div>}
                    value="inbox"
                    onClick={() => navigate(`/host/inbox`)}
                    icon={<ChatIcon />}
                />
                <BottomNavigationAction
                    sx={{ minWidth: '60px', padding: 0 }}
                    label={<div style={{ fontSize: '11px' }}>Календарь</div>}
                    value="calendar"
                    onClick={() => navigate(`/host/calendar`)}
                    icon={<CalendarMonthIcon />}
                />
                <BottomNavigationAction
                    sx={{ minWidth: '60px', padding: 0 }}
                    label={<div style={{ fontSize: '11px' }}>Объявления</div>}
                    value="properties"
                    onClick={() => navigate(`/host/properties`)}
                    icon={<LocalTaxiIcon />}
                />
                <BottomNavigationAction
                    sx={{ minWidth: '60px', padding: 0 }}
                    label={<div style={{ fontSize: '11px' }}>Профиль</div>}
                    value="profile"
                    onClick={() => navigate(`/host/profile`)}
                    icon={<DehazeOutlinedIcon />}
                />
            </BottomNavigation>
        </Paper>
    )
}