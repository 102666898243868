const initialState = {
    currency: null,
    currencies: null,
    iso_code: null,
}
//TODO
export default function currency(state= initialState, action) {
    switch(action.type){
        case "GET_CURRENCY":
            return {...state, currency: action.payload};
        case "GET_CURRENCIES":
            return {...state, currencies: action.payload};
        case "SET_ISO_CODE":
            return {...state, iso_code: action.payload};
        default: return state;
    }
};