const initialState = {
    roles: null
}
//TODO
export default function role(state= initialState, action) {
    switch (action.type) {
        case "GET_ROLES":
            return {...state, roles: action.payload};
        default: return state;
    }
};
