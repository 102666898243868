import React from "react"
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import MailIcon from '@mui/icons-material/Mail';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import Paper from '@mui/material/Paper';

export function Menu() {
    const navigate = useNavigate();

    return(
        <Paper
            id="bottom-nav"
            sx={{
                zIndex: 5,
                position: 'fixed !important',
                bottom: 0,
                left: 0,
                right: 0,
            }}
            elevation={3}
        >
                {!Cookies.get('token') ? (
                    <BottomNavigation showLabels value={'search'}>
                        <BottomNavigationAction
                            sx={{ minWidth: '60px', padding: 0 }}
                            label={<div style={{ fontSize: '11px' }}>Поиск</div>}
                            value="search"
                            onClick={() => navigate('/')}
                            icon={<SearchIcon />}
                        />
                        {/*<BottomNavigationAction
                            label="Сохраненные"
                            value="wishlist"
                            onClick={() => window.location = window.location.protocol + '//' + 'account.' + window.location.host + '/sign-in'}
                            icon={<FavoriteIcon />}
                        />*/}
                        <BottomNavigationAction
                            sx={{ minWidth: '60px', padding: 0 }}
                            label={<div style={{ fontSize: '11px' }}>Войти</div>}
                            value="sign-in"
                            onClick={() => navigate('/login')}
                            icon={<AccountCircleIcon />}
                        />
                    </BottomNavigation>
                ) : (
                    <BottomNavigation showLabels value={'search'}>
                        <BottomNavigationAction
                            sx={{ minWidth: '60px', padding: 0 }}
                            label={<div style={{ fontSize: '11px' }}>Поиск</div>}
                            value="search"
                            onClick={() => navigate('/')}
                            icon={<SearchIcon />}
                        />
                        <BottomNavigationAction
                            sx={{ minWidth: '60px', padding: 0 }}
                            label={<div style={{ fontSize: '11px' }}>Избранные</div>}
                            value="wishlists"
                            onClick={() => navigate('/guest/wishlists')}
                            icon={<FavoriteIcon />}
                        />
                        <BottomNavigationAction
                            sx={{ minWidth: '60px', padding: 0 }}
                            label={<div style={{ fontSize: '11px' }}>Поездки</div>}
                            value="reservations"
                            onClick={() => navigate('/guest/reservations')}
                            icon={<LocalTaxiIcon />}
                        />
                        <BottomNavigationAction
                            sx={{ minWidth: '60px', padding: 0 }}
                            label={<div style={{ fontSize: '11px' }}>Входящие</div>}
                            value="inbox"
                            onClick={() => navigate('/guest/inbox')}
                            icon={<MailIcon />}
                        />
                        <BottomNavigationAction
                            sx={{ minWidth: '60px', padding: 0 }}
                            label={<div style={{ fontSize: '11px' }}>Профиль</div>}
                            value="profile"
                            onClick={() => navigate('/guest/profile')}
                            icon={<AccountCircleIcon />}
                        />
                    </BottomNavigation>
                )}
        </Paper>
    )
}