export const getRegion = payload => {
    return {
        type: "GET_REGION",
        payload: payload
    }
}

export const getRegions = payload => {
    return {
        type: "GET_REGIONS",
        payload: payload
    }
}

export const setName = payload => {
    return {
        type: "SET_NAME",
        payload: payload
    }
}

export const setParentId = payload => {
    return {
        type: "SET_PARENT_ID",
        payload: payload
    }
}

export const setLat = payload => {
    return {
        type: "SET_LAT",
        payload: payload
    }
}

export const setLng = payload => {
    return {
        type: "SET_LNG",
        payload: payload
    }
}

