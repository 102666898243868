import { combineReducers } from "redux"
import property from "./reducers/property";
import user from "./reducers/user";
import currency from "./reducers/currency";
import region from "./reducers/region";
import propertyType from "./reducers/propertyType";
import log from "./reducers/log";
import role from "./reducers/role";
import permission from "./reducers/permission";
import urlRequest from "./reducers/urlRequest";
import countryRegister from "./reducers/countryRegister";
import booking from "./reducers/booking";
import propertyFavourite from "./reducers/propertyFavourite";
import amenity from "./reducers/amenity";
import amenityCategory from "./reducers/amenityCategory";
import ratingCoefficient from "./reducers/ratingCoefficient";
import review from "./reducers/review";
import reviewScoreCategory from "./reducers/reviewScoreCategory";
import bookingFeedback from "./reducers/bookingFeedback";
import widget from "./reducers/widget";
import internalSystem from "./reducers/internalSystem";
import keyStorageItem from "./reducers/keyStorageItem";

export default combineReducers({ property, user, currency, propertyType, region, log, role, permission, urlRequest, countryRegister, booking, propertyFavourite, amenity, amenityCategory, ratingCoefficient, review, reviewScoreCategory, bookingFeedback, widget, internalSystem, keyStorageItem });
