const initialState = {
    propertyFavourite: null,
    propertyFavourites: null,
}

export default function propertyFavourite(state= initialState, action) {
    switch(action.type){
        case "GET_PROPERTY_FAVOURITES":
            return {...state, propertyFavourites: action.payload};
        case "GET_PROPERTY_FAVOURITE":
            return {...state, propertyFavourite: action.payload};
        default: return state;
    }
};