const initialState = {
    country_register: null,
    country_registers: null,
}

export default function countryRegister(state= initialState, action) {
    switch(action.type){
        case "GET_COUNTRY_REGISTER":
            return {...state, country_register: action.payload};
        case "GET_COUNTRY_REGISTERS":
            return {...state, country_registers: action.payload};
        default: return state;
    }
};